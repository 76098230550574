footer {
    width: 100%;
    color: var(--blue);
    font-size: 16px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-sb);
    display: flex;
    justify-content: space-between;
    padding: 45px 80px;
}

footer > div {
    display: flex;
    align-items: center;
}

.logo {
    width: 57px;
}

.contactInfo img {
    height: 24px;
}

.contactInfo {
    color: var(--blue);
    font-weight: var(--fw-r);
    display: flex;
    align-items: center;
    gap: 80px;
    margin-left: 53px;
}

.contactInfo div {
    display: flex;
    align-items: center;
    gap: 20px;
}

.socialMediaLinks {
    display: flex;
    gap: 24px;
    margin-right: 55px;
}

.socialMediaLinks img {
    height: 32px;
}

.links {
    display: flex;
    gap: 32px;
}

.copyrightText {
    width: max-content;
    color: var(--blue);
    font-size: 16px;
    font-family: var(--ff-qs);
    margin-top: 19px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 16px;
}

/* mobile view styles */

@media screen and (max-width: 834px) {
    footer {
        flex-direction: column;
        gap: 51px;
        padding: 32px 33px 35px;
    }

    footer div {
        align-items: flex-start;
    }

    footer > div:first-child {
        flex-direction: column;
    }

    .logo {
        width: 46px;
    }

    .contactInfo {
        flex-direction: column;
        align-items: flex-start;
        gap: 22px;
        margin: 25px 0px 0px;
    }

    .contactInfo div {
        gap: 12px;
    }

    .socialMediaLinks {
        margin-right: auto;
    }

    .links {
        flex-direction: column;
        gap: 16px;
    }

    .copyrightText {
        font-size: 14px;
    }
}

/* tablet view styles */

@media screen and (min-width: 834px) and (max-width: 1365px) {
    
    footer {
        flex-direction: column;
        gap: 48px;
        padding: 47px 80px 53px;
    }

    footer > div, footer > div:first-child {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .contactInfo{
        flex-direction: row;
        gap: 37px;
        margin-left: 40px;
        margin-top: 0px;
    }

    .contactInfo img {
        height: 25px;
    }

    .socialMediaLinks {
        margin-right: 52px;
    }

    .links {
        gap: 24px;
    }
    .joinOptns {
        display: flex;
        margin-top: 20px;
        margin-bottom: 65px;
    }
    .btn {
        cursor: pointer;
    }
}