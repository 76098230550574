::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--gray-two);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: var(--gray-two);
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: var(--gray-two);
}

.body {
    background-color: var(--white);
}

.border {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0px 32px;
}

.border > div {
    height: 1px;
    width: 100%;
    background-color: var(--gray-four);
}

.btn {
    cursor: pointer;
}

/* header */

.header {
    width: 100%;
    background-color: var(--white);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 32px;
    filter: drop-shadow(0px 6px 15px rgba(126, 126, 126, 0.25));
}

.websiteLogo {
    width: 37px;
}

.header > p {
    color: var(--blue);
    font-size: 16px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-sb);
    line-height: 24px;
}

.burgerMenu {
    height: 24px;
    transition: transform 200ms ease-out;
}

.headerBtn {
    width: 140px;
    background-color: var(--blue);
    color: var(--white);
    text-align: center;
    font-size: 14px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-sb);
    line-height: 20px;
    border-radius: 8px;
    padding: 6px 0;
    transform: scale(1, 0);
    transform-origin: top;
    transition: transform 200ms ease-in;

    position: absolute;
    right: 32px;
    bottom: -35px;
}

.pageHeadings {
    text-align: center;
    margin: 40px 32px 24px;
}

.pageHeading {
    color: var(--blue);
    text-align: center;
    font-size: 28px;
    font-family: var(--ff-ns);
    font-weight: var(--fw-b);
    line-height: 36px;
}

.pageHeadings > .accent {
    background-color: var(--blue);
    color: var(--blue);
    padding: 0px 5px;
    margin: 1px 0px;
    line-height: 42px;

    position: relative;
    background-image: 
        linear-gradient(var(--blue), var(--blue)),
        linear-gradient(#fff,#fff);
    -webkit-background-clip:
        padding-box,
        text;
    background-clip:
        padding-box,
        text;
    background-size: 0% 100%;
    background-repeat: no-repeat;
    background-position: left;
    animation: revealer-text 0.8s 1s forwards;
    animation-delay: 4500ms;
}

@keyframes revealer-text {
  0% {
    background-size: 0% 100%;
    color: var(--blue);
  }
  100% {
    background-size: 100% 100%;
    background-position: left;
    color: var(--white);
  }
}

/*********** 
section-info
***********/

.sectionInfo {
    width: 100%;
    background-image: url("../../assets/images/background-image.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 32px;
}

.infoBox {
    width: 100%;
    max-width: 350px;
    min-height: 268px;
    aspect-ratio: 1/1;
    background-color: rgba(1, 30, 70, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 17px;
    margin-bottom: 16px;
}

.infoBox:last-child {
    margin-bottom: 0px;
}

.infoBox > img {
    height: 64px;
    margin-bottom: 24px;
}

.infoBox > h4 {
    color: var(--gray-two);
    font-size: 16px;
    font-family: var(--ff-ns);
    font-weight: var(--fw-sb);
    line-height: 20px;
    text-align: center;
}

.infoBox > h4 > span {
    color: var(--white);
}

/*********** 
section-form
***********/

.sectionForm {
    padding: 64px 32px 88px;
}

.sectionForm > h1 {
    color: var(--blue);
    font-size: 28px;
    font-family: var(--ff-ns);
    font-weight: var(--fw-b);
    line-height: 36px;
    text-align: center;
}

.sectionForm > p {
    color: var(--black);
    font-size: 12px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-r);
    line-height: 16px;
    text-align: center;
    margin-top: 8px;
}

.signedUp {
    color: var(--blue-impt);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 76px;
    margin-bottom: 90px;
}

.signedUp > img {
    width: 100px;
}

.signedUp > h3 {
    font-size: 28px;
    font-family: var(--ff-ns);
    font-weight: var(--fw-sb);
    line-height: 36px;
    margin-top: 26px;
    margin-bottom: 12px;
}
.signedUp > p {
    font-size: 16px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-r);
    line-height: 24px;
}

.expertForm {
    margin-top: 40px;
}

.expertForm > div {
    margin-bottom: 16px;
}

.expertForm label {
    color: var(--blue);
    font-family: var(--ff-qs);
    font-weight: var(--fw-sb);
    display: none;
}

.expertForm input, 
.expertForm select {
    width: 100%;
    background-color: var(--white);
    color: var(--blue);
    font-size: 14px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-r);
    border: 1px solid var(--blue-two);
    border-radius: 5px;
    padding: 12px 6px;
}

.expertForm input:focus, 
.expertForm select {
    outline: 0;
}

.addLinks {
    color: var(--gray-one);
    font-size: 16px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-sb);
    line-height: 24px;
    margin: 32px 0px 24px;
}

.addLinks > div:first-child > p {
    margin-bottom: 24px;
}

.addLinks > div:first-child > p > span {
    color: var(--gray-one);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}

.addLinks > div {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.addLinks img {
    height: 32px;
    width: 32px;
    margin-right: 24px;
}

.addLinks > div:first-child {
    justify-content: center;
    margin: 0px;
}

.addLinks > div:first-child > img {
    display: none;
}

.addLink {
    margin-top: 8px;
}

.addLink img {
    height: 20px;
    margin-right: 8px;
}

.addLink p {
    color: var(--blue-three);
    font-size: 14px;
    font-family: var(--ff-ns);
    font-weight: var(--fw-m);
    line-height: 19px;
}

.expertForm > div:nth-last-child(1) {
    display: initial;
}

.expertForm > div:nth-last-child(1) p {
    color: var(--red);
    font-size: 14px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-r);
    margin-bottom: 32px;
}

.submitBtn {
    width: 100%;
    background-color: var(--gray-one);
    color: var(--white);
    font-size: 16px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-sb);
    line-height: 24px;
    border: none;
    border-radius: 12px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 0px 15px rgba(126, 126, 126, 0.3);
    padding: 12px 0px;
    margin-top: 32px;
}

/***************
Tablet View styles
***************/

@media screen and (min-width: 834px) {

    /* header */

    .header {
        height: 64px;
        padding: 0px 80px;
    }

    .websiteLogo {
        width: 44px;
    }

    .header > p {
        font-size: 18px;
        line-height: 28px;
    }

    .burgerMenu {
        display: none;
    }

    .headerBtn {
        width: 196px;
        background-color: var(--blue);
        color: var(--white);
        font-size: 16px;
        line-height: 24px;
        border-radius: 46px;
        padding: 8px 0px;

        position: initial;
    }

    /* .headerBtn:hover {
        background-color: var(--white);
        color: var(--blue);
    } */

    .pageHeadings {
        text-align: center;
        margin: 64px 80px 96px;
    }

    .pageHeading {
        /* width: min-content; */
        font-size: 36px;
        line-height: 48px;
    }

    /* section-info */

    .sectionInfo {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        gap: 24px;
        padding: 0px 80px;
    }

    .infoBox {
        height: 293px;
        width: 325px;
        padding: 47px 24px;
        margin: 0px;
    }

    .infoBox > img {
        height: 76px;
    }

    .infoBox > h4 {
        font-size: 20px;
        line-height: 28px;
    }

    /* section-form */

    .sectionForm {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 76px 80px 128px;
    }

    .sectionForm > h1 {
        font-size: 48px;
        line-height: 64px;
    }

    .sectionForm > p {
        width: 60%;
        font-size: 16px;
        line-height: 24px;
        margin-top: 14px;
    }

    .expertForm {
        width: 100%;
        max-width: 750px;
        margin-top: 70px;
    }

    .expertForm div {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
    }

    .expertForm > div:nth-last-child(2) {
        flex-direction: column;
    }

    .expertForm label {
        font-size: 18px;
        line-height: 28px;
        display: initial;
    }

    .expertForm input, 
    .expertForm select,
    .addLinks > div:first-child > p {
        width: 65%;
        max-width: 475px;
        font-size: 16px;
        line-height: 24px;
        border-width: 3px;
        padding: 9px;
    }

    .addLinks {
        margin: 64px 0px;
    }

    .addLinks > div:first-child > p {
        font-size: 24px;
        line-height: 32px;
        padding: 0px;
        margin-bottom: 34px;
    }

    .addLinks > div:first-child > p > span {
        font-size: 14px;
        line-height: 16px;
    }

    .addLinks > div {
        margin-bottom: 26px;
    }

    .addLinks > div:first-child {
        justify-content: space-between;
    }

    .addLinks > div:first-child > img {
        display: initial;
    }

    .addLinks > div:nth-last-child(1) {
        justify-content: flex-start;
        margin-bottom: 0px;
    }

    .addLinks img {
        height: 47px;
        width: 47px;
    }

    .addLinks > div:first-child {
        margin: 0px;
    }

    .addLink img {
        height: 24px;
    }

    .addLink p {
        font-size: 16px;
        line-height: 22px;
    }

    .submitBtn {
        padding: 17px 0px 20px;
        margin-top: 21px;
    }

    .border > div {
        width: 85%;
    }

}


/***************
Desktop View styles
***************/

@media screen and (min-width: 1366px) {

    /* header */

    .pageHeadings {
        margin-right: 200px;
        margin-left: 200px;
    }

    .pageHeading {
        /* width: min-content; */
        font-size: 36px;
        line-height: 55px;
    }

    /* section-info */
    
    .sectionInfo {
        height: 372px;
        display: flex;
        justify-content: space-around;
    }

    .infoBox {
        height: 100%;
        width: 30%;
        max-width: 500px;
        justify-content: center;
        padding: 0px 29px;
    }

    .infoBox > img {
        height: 96px;
        margin-bottom: 32px;
    }

    /* section-form */

    .sectionForm {
        padding-right: 0px;
        padding-left: 0px;
    }

    .expertForm {
        width: 60%;
        margin-top: 74px;
    }

    .expertForm input, 
    .expertForm select,
    .addLinks > div:first-child > p {
        width: 100%;
        max-width: 518px;
        padding: 9px 10px;
    }

    .addLinks {
        margin: 65px 0px;
    }

    .addLinks > div:first-child > p {
        padding: 0px 0px;
        margin-bottom: 32px;
    }

    .addLinks > div:nth-last-child(2) {
        margin-bottom: 33px;
    }

    .addLinks > div:nth-last-child(1) {
        margin-bottom: 20px;
    }

    .expertForm > div:nth-last-child(1) p {
        margin-bottom: 32px;
    }

    .border > div {
        width: 95%;
    }
}