html, body {
    overflow-x: hidden
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--gray-two);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: var(--gray-two);
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: var(--gray-two);
}

.btn {
    cursor: pointer;
}

.border {
    width: 100%;
    display: flex;
    justify-content: center;
}

.border > div {
    height: 1px;
    width: 92%;
    background-color: var(--gray-four);
}

/*********** 
section-1
***********/

.sectionOne {
    height: 100vh;
    min-height: 800px;
    max-height: 875px;
    background-color: var(--blue);
}

.sectionReviewHeader {
    height: 100vh;
    min-height: 100px;
    max-height: 10px;
    background-color: var(--blue);
}

.header {
    width: 100%;
    color: var(--white);
    font-size: 18px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-sb);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 80px;
}

.websiteLogo {
    width: 44px;
}
.productImage {
    /* height: 100%; */
    /* position: absolute; */
    /* bottom: -175px */
    width: 39px;
    height: 39px;
}
.socialMediaLinks {
    display: flex;
    gap: 24px;
    margin-right: 55px;
}

.socialMediaLinks img {
    height: 32px;
}
.burgerMenu{
    height: 24px;
    transition: transform 200ms ease-out;
}

.headerBtns {
    display: flex;
    align-items: center;
    z-index: 2;
}

.headerBtns > div {
    position: relative;
}

.addProduct {
    cursor: pointer;
    font-size: 16px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-sb);
    margin-right: 24px;
}

.addProduct:hover {
    text-decoration: underline;
}

.partner {
    font-size: 16px;
}

.partner:hover {
    text-decoration: underline;
}

.partnerOptns {
    width: max-content;
    height: 90px;
    background-color: rgba(25, 52, 88, 0.9);
    color: var(--gray-four);
    font-size: 16px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-r);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 4px;
    padding: 8px 18px;
    transform: scale(1, 0);
    transform-origin: top;
    transition: transform 200ms ease-in;

    position: absolute;
    left: -10px;
}

.partnerOptns > p:hover, .partnerOptns > a:hover {
    text-decoration: underline;
}

.partnerOptns > .border > div {
    background-color: var(--gray-one);
}

.headerBtns > div:hover > .partnerOptns {
    transform: scale(1, 1);
}

.signUpBeta {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 0px 15px rgba(126, 126, 126, 0.3);
    border-radius: 46px;
    padding: 16px 26.5px;
    margin-left: 24px;
    transition: background-color 200ms ease-in, color 200ms ease-in;
}

.headerBtns .signUpBeta:hover {
    background-color: var(--white);
    color: var(--blue);
}

.burgerMenu, .headerBtns > .border {
    display: none;
}

.sectionOne h1 {
    font-size: 48px;
    font-family: var(--ff-ns);
    font-weight: var(--fw-b);
    margin: 0px 32px;
}

.sectionOne h1:first-child {
    color: var(--white);
    text-align: center;
}

.headBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 64px;
}
.headBody2 {
    color: var(--white);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 64px;
}

.headBody h1:last-child {
    margin-top: 8px;
}

.accentH1 {
    width: fit-content;
    color: var(--white);
    text-align: center;
    padding: 0px 4px 8px;
    margin-top: 8px;
    margin-bottom: 24px;

    position: relative;
    background-image: 
        linear-gradient(#fff, #fff),
        linear-gradient(#fff,#fff);
    -webkit-background-clip:
        padding-box,
        text;
    background-clip:
        padding-box,
        text;
    background-size: 0% 100%;
    background-repeat: no-repeat;
    background-position: left;
    animation: revealer-text 0.8s 1s forwards;
    animation-delay: 4000ms;
    z-index: 1;
}

@keyframes revealer-text {
  0% {
    background-size: 0% 100%;
    color: var(--white);
  }
  100% {
    background-size: 100% 100%;
    background-position: left;
    color: var(--blue);
  }
}

.headBody > div {
    height: 650px;
    margin-top: 32px;
}

.mockup {
    height: 100%;
    /* position: absolute; */
    /* bottom: -175px */
}

/***********
section-2 
***********/

.sectionTwo {
    height: 536px;
    color: var(--blue);
    font-size: 18px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-r);
    display: flex;
    align-items: flex-end;
    padding-bottom: 44px;
}

.infos {
    width: 100%;
    display: flex;
    gap: 64px;
    margin: 0 80px;
}

.infos > div {
    width: 50%;
    display: flex;
    justify-content: space-around;
    gap: 24px;
}

.info {
    width: 35%;
    min-width: 136px;
    max-width: 272px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.infoIcon {
    width: 64px;
    height: 64px;
    /* margin-bottom: 16px; */
}

/*********** 
section-3
***********/

.sectionThree {
    width: 100%;
    background-image: url('../../assets/images/sec-bg.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 850px;
    background-color: var(--blue);
    padding-top: 178px;
    padding-bottom: 98px;
    padding-left: 190px;
    padding-right: 190px;
}

.sectionThree > div {
    width: 100%;
    display: flex;
    /* justify-content: space-around; */
    align-items: center;
    gap: 108px;
    margin-bottom: 8px;
}

.sectionThree > div:nth-child(2) {
    justify-content: flex-end;
}

.sectionThree h2 {
    color: var(--blue-four);
    font-size: 36px;
    font-family: var(--ff-ns);
    font-weight: var(--fw-sb);
}

.sectionThree p {
    color: var(--blue-four);
    font-size: 18px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-r);
    line-height: 28px;
    margin-top: 24px;
}

.sectionThree img {
    width: 325px;
    /* margin: 0px 32px; */
}


/*********** 
section-4
***********/

.sectionFour {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;
    padding-bottom: 41px;
}

.sectionFour > h1 {
    color: var(--blue);
    text-align: center;
    font-size: 48px;
    font-family: var(--ff-ns);
    font-weight: var(--fw-b);
    margin-bottom: 16px;
}

.sectionFour > p {
    color: var(--black);
    text-align: center;
    font-size: 16px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-r);
    margin-bottom: 28px;
}

.sectionFour > .border div {
    width: 40%;
}

 .signedUp {
    color: var(--blue-impt);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 76px;
    margin-bottom: 90px;
}

.signedUp > img {
    width: 100px;
}

.signedUp > h3 {
    font-size: 28px;
    font-family: var(--ff-ns);
    font-weight: var(--fw-sb);
    line-height: 36px;
    margin-top: 26px;
    margin-bottom: 12px;
}

.signedUp > p {
    font-size: 16px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-r);
    line-height: 24px;
}

.sectionFour > .border > div {
    width: 100%;
    min-width: 670px;
    max-width: 750px;
}

.betaForm {
    width: 100%;
    min-width: 670px;
    max-width: 750px;
    margin-top: 47px;
}

.betaForm > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}

.betaForm label {
    color: var(--blue);
    font-size: 18px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-sb);
}

.betaForm input {
    width: 100%;
    color: var(--blue);
    max-width: 518px;
    font-size: 16px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-r);
    border: 3px solid var(--blue-two);
    border-radius: 5px;
    padding: 10px;
}

.betaForm input:focus {
    outline: 0;
}

.betaForm div:nth-child(3) {
    margin-bottom: 16px;
}

.betaForm div:nth-child(4) p {
    width: 100%;
    max-width: 518px;
    color: var(--red);
    font-size: 14px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-r);
}

.betaForm div:nth-child(4) {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}

.betaForm .signUpBeta {
    width: 100%;
    max-width: 520px;
    background-color: var(--blue);
    color: var(--white);
    line-height: 28px;
    font-size: 18px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-sb);
    justify-content: center;
    padding: 17px 0px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 0px 15px rgba(126, 126, 126, 0.3);
    border: 0;
    border-radius: 12px;
    margin-top: 32px;
}

/*********** 
section-5 
***********/

.sectionFive {
    width: 100%;
    padding: 88px 80px 40px;
}

.sectionFive h2 {
    color: var(--blue);
    text-align: center;
    font-size: 36px;
    font-family: var(--ff-ns);
    font-weight: var(--fw-b);
}

.storeLogos {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 40px;
    margin-bottom: 88px;
}

.storeLogos > img {
    height: 60px;
}

.sectionFive h3 {
    font-size: 28px;
    font-family: var(--ns);
    font-weight: var(--fw-sb)
}

.joinOptns {
    display: flex;
    margin-top: 20px;
    margin-bottom: 65px;
}

.joinOptns > p, .joinOptns > a {
    font-size: 18px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-r);
    margin-right: 40px;
    text-decoration: underline;
}

.sectionFive > .signUpBeta {
    width: max-content;
    background-color: var(--blue);
    color: var(--white);
    font-size: 18px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-sb);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 0px 15px rgba(126, 126, 126, 0.3);
    margin: 0px;
}

/******************  
tablet view styles
******************/

@media screen and (min-width: 834px) and (max-width: 1365px) {

    .border {
        padding: 0px 80px;
    }

    .border > div {
        width: 100%;
    }

    /* section -1 */

    .headBody {
        margin: 64px 80px;
    }

    .partnerOptns .border {
        padding: 0px 0px;
    }

    /* section-2 */

    .sectionTwo {
        /* height: 828px; */
        padding: 44px 80px; 
    }

    .infos {
        /* flex-direction: column; */
        margin: 0px;
    }

    .infos > div {
        width: 100%;
        justify-content: space-between;
        gap: 64px;
    }

    .info {
        width: 100%;
        max-width: 272px;
        font-size: 16px;
        line-height: 24px;
    }

    .info > p {
        margin-top: 24px;
    }

    /* section-3 */

    .sectionThree {
        background-size: 700px;
        padding: 178px 80px 98px;
    }

    .sectionThree > div {
        gap: 24px;
    }

    .sectionThree h2 {
        font-size: 28px;
        line-height: 36px;
    }

    /* section-4 */ 

    .sectionFour {
        padding: 98px 80px 40px;
    }

    .sectionFour > .border {
        padding: 0px 0px;
    }
    
}


/******************  
mobile view styles
******************/

@media screen and (max-width: 833px) {

    body {
        background-color: var(--white);
    }

    .border > div {
        width: 85%;
    }

    /****** section-1 ******/

    .sectionOne {
        max-height: 600px;
        min-height: 500px;
        padding: 0px 32px;
    }

    .sectionReviewHeader {
        min-height: 100px;
        max-height: 10px;
        padding: 0px 32px;
    }

    .header {
        padding: 10px 0px;
    }

    .websiteLogo {
        width: 39px;
    }

    .burgerMenu, .headerBtns > .border {
        display: initial;
    }

    .menu {
        position: relative;
    }

    .headerBtns {
        width: 140px;
        height: 200px;
        background-color: var(--blue);
        
        flex-direction: column-reverse;
        justify-content: space-between;
        align-items: center;
        border-radius: 8px;
        box-shadow: 0px 0px 10px rgba(252, 252, 252, 0.5);

        position: absolute;
        right: 0;

        transform-origin: top;
        transition: transform 200ms ease-in;
    }

    .partnerOptns > .border > div {
        background-color: var(--blue-one);
        margin: 4.78px 0px 3.22px;
    }

    .headerBtns > div:first-child {
        width: 100%;
    }

    .headerBtns > .signUpBeta {
        font-size: 14px;
        font-family: var(--ff-qs);
        font-weight: var(--fw-r);
        border-radius: 0px;
        box-shadow: none;
        padding: 0px;
        margin: 8px 0px 9.11px;
        transition: none;
    }

    .headerBtns .signUpBeta:hover {
        background-color: var(--blue);
        color: var(--white)
    }

    .headerBtns > .border > div {
        width: 140px;
        background-color: var(--blue-one);
    }

    .addProduct {
        text-align: center;
        font-size: 14px;
        font-weight: var(--fw-r);
        padding: 8px 0px;
        margin-right: 0px;
        border-top: 1px solid var(--blue-one);
    }

    .partner {
        text-align: center;
        font-size: 14px;
        font-family: var(--ff-qs);
        font-weight: var(--fw-r);
        padding: 8px 0px 12px;
    }

    .partnerOptns {
        height: min-content;
        width: 100%;
        background-color: rgba(0,0,0,0);
        color: var(--gray-three);
        font-size: 14px;
        font-family: var(--ff-qs);
        font-weight: var(--fw-r);
        align-items: flex-end;
        border-radius: 0px;
        padding: 0px;
        margin: 0px 0px 15.44px;
        padding-right: 10.36px;
        transform: scale(1,1);

        position: initial;
    }

    .partnerOptns .border {
        justify-content: flex-end;
    }

    .partnerOptns .border > div {
        width: 92px;
    }

    .headBody {
        margin: 0px;
        padding-top: 40px;
    }

    .headBody h1 {
        font-size: 28px;
        line-height: 36px;
        margin: 0px;
    }

    .headBody > div {
        max-height: 525px;
        /* height: 80vh; */
        min-height: 400px;
        /* width: 100%; */
        /* max-width: 500px; */
        padding: 0px 32px;
    }

    /****** section-2 ******/

    .sectionTwo {
        height: 618px;
        padding: 32px;
    }

    .infos {
        flex-direction: row;
        gap: 24px;
        margin: 0px;
        flex-wrap: nowrap;
    }

    .infos > div {
        width: 100%;
        flex-direction: column;
        justify-content: flex-start;
        gap: 32px;
    }

    .infos > div:last-child {
        align-items: flex-end;
    }

    .info {
        width: 100%;
        min-height: 120px;
        min-width: 0px;
        font-size: 16px;
        line-height: 24px;
    }

    .infoIcon {
        height: 40px;
        width: 40px;
        margin-bottom: 16px;
    }

    /****** section-3 ******/

    .sectionThree {
        background-image: url('../../assets/images/sec-bg-mobile.png');
        background-size: 300px;
        padding: 35px 32px 58px;
    }

    .sectionThree  h2 {
        font-size: 20px;
        line-height: 28px;
    }

    .sectionThree p {
        font-size: 14px;
        margin-top: 16px;
    }

    .sectionThree > div {
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        margin-bottom: 64px;
    }

    .sectionThree > div:nth-child(2) {
        flex-direction: column-reverse;
        align-items: flex-end;
    }

    .sectionThree img {
        width: 216px;
    }

    /****** section-4 ******/
    
    .sectionFour {
        padding: 32px;
    }

    .sectionFour > h1 {
        font-size: 28px;
        line-height: 36px;
        margin-top: 32px;
        margin-bottom: 8px;
    }

    .sectionFour > p {
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 16px;
    }

    .sectionFour .border div {
        width: 100%;
    }

    .sectionFour > .border {
        padding: 0px 0px;
    }

    .sectionFour > .border > div {
        width: 100%;
        min-width: 0px;
    }

    .betaForm {
        min-width: 0px;
        margin-top: 24px;
    }

    .betaForm label {
        display: none;
    }

    .betaForm input {
        width: 100%;
        max-width: none;
        font-size: 14px;
        line-height: 20px;
        border-width: 1px;
        padding: 12px 6px;
        border-width: 1px;
    }

    .betaForm .signUpBeta {
        min-width: 100%;
    }

    /****** section-5 ******/

    .sectionFive {
        padding: 64px 32px 24px;
    }

    .sectionFive > h2 {
        font-size: 20px;
        line-height: 28px;
    }

    .storeLogos {
        margin-bottom: 68px;
    }

    .storeLogos img {
        height: 39px;
    }

    .sectionFive h3 {
        font-size: 18px;
        line-height: 24px;
    }

    .joinOptns {
        margin-top: 12px;
        margin-bottom: 40px;
    }

    .joinOptns > p, .joinOptns > a {
        font-size: 14px;
        line-height: 20px;
    }

    .sectionFive > .signUpBeta {
        font-size: 14px;
        line-height: 20px;
        padding: 12.5px 15px;
    }

}