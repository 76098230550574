::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--gray-two);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: var(--gray-two);
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: var(--gray-two);
}

.body {
    height: 100vh;
    width: 100%;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 32px;
}

.heading {
    color: var(--blue);
    text-align: center;
    font-size: 28px;
    font-family: var(--ff-ns);
    font-weight: var(--fw-b);
    line-height: 36px;
    margin-bottom: 8px;
}

.loginForm {
    width: 100%;
    min-width: 0px;
    max-width: 400px;
    margin-top: 24px;
}

.loginForm > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}

.loginForm label {
    color: var(--blue);
    display: none;
    font-size: 18px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-sb);
}

.loginForm input {
    width: 100%;
    font-size: 14px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-r);
    line-height: 20px;
    border: 1px solid var(--blue-two);
    border-radius: 5px;
    padding: 12px 6px;
}

.loginForm input:focus {
    outline: 0;
}

.loginForm button {
    width: 100%;
    background-color: var(--gray-one);
    color: var(--white);
    font-size: 16px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-sb);
    line-height: 24px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 0px 15px rgba(126, 126, 126, 0.3);
    border-radius: 12px;
    border: 0px;
    padding: 9px 0px 12px;
    margin-top: 16px;
}

/**********
tablet styles
**********/

@media screen and (min-width: 834px) {

    .body {
        padding: 0px 80px;
    }

    .heading {
        font-size: 48px;
        line-height: 64px;
        margin-bottom: 16px;
    }

    .loginForm {
        max-width: 600px;
        font-size: 16px;
        line-height: 24px;
        margin-top: 80px;
    }

    .loginForm > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
    }

    .loginForm label {
        color: var(--blue);
        font-size: 18px;
        font-family: var(--ff-qs);
        font-weight: var(--fw-sb);
        line-height: 28px;
        display: initial;
    }

    .loginForm input {
        max-width: 456px;
        border-width: 3px;
        padding-left: 9.11px;
    }

    .loginForm > div:last-child {
        justify-content: flex-end;
        margin-bottom: 0px;
        margin-top: 60px;
    }

    .loginForm > div:last-child p {
        max-width: 456px;
    }

    .loginForm button {
        max-width: 456px;
        font-size: 18px;
        line-height: 28px;
        align-self: flex-end;
        padding: 17px 0px 20px;
        margin-top: 0px;
    }
}

/**********
desktop styles
**********/

@media screen and (min-width: 1366px) {
    .loginForm {
        max-width: 700px;
        margin-top: 84px;
    }

    .loginForm input {
        max-width: 520px;
        padding-left: 10.4px;
    }

    .loginForm > div:last-child p {
        max-width: 520px;
    }
    
    .loginForm button {
        max-width: 520px;
    }
}