@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@500;600;700&family=Quicksand:wght@400;600&display=swap');

:root {
    --blue: #011E46;
    --blue-impt: #015DD3;
    --blue-one: #3C5271;
    --blue-two: #B3CEF2;
    --blue-three: #347DDC;
    --blue-four: #F2F7FD;
    --blue-five: #001735;

    --white: #fff;
    --white-two: #F5F5F5;

    --black: #000;

    --red: #CE0F0F;

    --gray-one: #677890;
    --gray-two: #99A5B5;
    --gray-three: #B3BBC7;
    --gray-four: #E6E8EC;

    --ff-ns: 'Noto Sans';
    --ff-qs: 'Quicksand';

    --fw-r: 400;
    --fw-m: 500;
    --fw-sb: 600;
    --fw-b: 700;
}

* {
    box-sizing: border-box;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

body {
    background-color: var(--white);
}

p, h1, h2, h3, h4 {
    margin: 0px;
}

p {
    font-family: var(--ff-qs);
}

a {
    color: inherit;
    text-decoration: none;
}