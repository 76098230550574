::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--gray-one);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: var(--gray-one);
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: var(--gray-one);
}

input:focus {
    outline: 0;
}

.body {
    background-color: var(--white);
}

.border {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0px 32px;
}

.border > div {
    height: 1px;
    width: 100%;
    background-color: var(--gray-four);
}

.btn {
    cursor: pointer;
}

/* header */

.header {
    width: 100%;
    background-color: var(--blue);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 32px;
    filter: drop-shadow(0px 6px 15px rgba(126, 126, 126, 0));
}

.websiteLogo {
    width: 39px;
}

.header > p {
    color: var(--white);
    font-size: 16px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-sb);
    line-height: 24px;
}

.burgerMenu {
    height: 24px;
    transition: transform 200ms ease-out;
}

.headerBtn {
    background-color: var(--blue);
    color: var(--white);
    text-align: center;
    font-size: 14px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-sb);
    line-height: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(252, 252, 252, 0.5);
    padding: 8.5px 25px;
    transform: scale(1, 0);
    transform-origin: top;
    transition: transform 200ms ease-in;

    position: absolute;
    right: 32px;
    bottom: -40px;
}

/* section-head */

.sectionHead {
    background-color: var(--blue);
    background-image: url("../../assets/images/bg-brand.svg");
    background-repeat: no-repeat;
    background-position: right 30px top;
    padding: 40px 32px 48px;
}

.pageHeadings {
    text-align: center;
    margin-bottom: 48px;
}

.pageHeading {
    color: var(--white);
    font-size: 28px;
    font-family: var(--ff-ns);
    font-weight: var(--fw-b);
    line-height: 36px;
}

.pageHeadings > .accent {
    color: var(--white);
    padding: 0px 5px;
    margin: 1px 0px;
    line-height: 42px;

    position: relative;
    background-image: 
        linear-gradient(#fff, #fff),
        linear-gradient(#fff,#fff);
    -webkit-background-clip:
        padding-box,
        text;
    background-clip:
        padding-box,
        text;
    background-size: 0% 100%;
    background-repeat: no-repeat;
    background-position: left;
    animation: revealer-text 0.8s 1s forwards;
    animation-delay: 3800ms;
}

@keyframes revealer-text {
  0% {
    background-size: 0% 100%;
    color: var(--white);
  }
  100% {
    background-size: 100% 100%;
    background-position: left;
    color: var(--blue);
  }
}

.emailInput,
.emailInput input,
.emailInput a{
    width: 100%;
}

.emailInput input {
    background-color: var(--blue-five);
    color: var(--white);
    font-size: 16px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-r);
    line-height: 24px;
    border: 2px solid var(--gray-one);
    padding: 10.5px 12.32px;
    margin-bottom: 16px;
}

.emailInput a {
    background-color: var(--blue-impt);
    color: var(--white);
    text-align: center;
    font-size: 16px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-sb);
    line-height: 24px;
    display: block;
    border: 0px;
    border-radius: 8px;
    padding: 7.46px;
}

/* section-information */

.sectionInformation {
    padding: 32px 32px 48px;
}

.accentBlueImpt {
    color: var(--blue-impt);
}

.accentBlue {
    color: var(--blue-five);
    font-weight: var(--fw-sb);
}

.info {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 32px;
}

.info img {
    width: 218px;
    margin-bottom: 16px;
}

.info h3 {
    color: var(--blue);
    font-size: 18px;
    font-family: var(--ff-ns);
    font-weight: var(--fw-sb);
    line-height: 24px;
    margin-bottom: 16px;
}

.info p {
    color: var(--gray-one);
    font-size: 14px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-r);
    line-height:  20px;
}

/* section-form */

.sectionForm {
    padding: 14px 32px 88px;
}

.sectionForm > h1 {
    color: var(--blue);
    text-align: center;
    font-size: 28px;
    font-family: var(--ff-ns);
    font-weight: var(--fw-b);
    line-height: 36px;
    margin-bottom: 8px;
}

.sectionForm > p {
    color: var(--blue);
    text-align: center;
    font-size: 12px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-r);
    line-height: 16px;
}

.sectionForm .accent {
    font-weight: var(--fw-sb);
}

.signedUp {
    color: var(--blue-impt);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 76px;
    margin-bottom: 90px;
}

.signedUp > img {
    width: 100px;
}

.signedUp > h3 {
    font-size: 28px;
    font-family: var(--ff-ns);
    font-weight: var(--fw-sb);
    line-height: 36px;
    margin-top: 26px;
    margin-bottom: 12px;
}
.signedUp > p {
    font-size: 16px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-r);
    line-height: 24px;
}

.brandForm {
    width: 100%;
    margin-top: 40px;
}

.brandForm label {
    display: none;
}

.brandForm ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--gray-two);
  opacity: 1; /* Firefox */
}

.brandForm :-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: var(--gray-two);
}

.brandForm ::-ms-input-placeholder { /* Microsoft Edge */
  color: var(--gray-two);
}

.brandForm > div {
    margin-bottom: 16px;
}

.brandForm input {
    width: 100%;
    color: var(--blue);
    font-size: 16px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-r);
    line-height: 24px;
    border-radius: 5px;
    border: 1px solid var(--blue-two);
    padding: 12px 5.91px 9px;
}

.brandForm > div:last-child {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    margin-bottom: 0px;
}

.brandForm > div:last-child p {
    width: 100%;
    color: var(--red);
    font-size: 14px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-r);
    margin-bottom: 32px;
}

.brandForm button {
    width: 100%;
    background-color: var(--gray-one);
    color: var(--white);
    font-size: 16px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-sb);
    line-height: 24px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 0px 15px rgba(126, 126, 126, 0.3);
    border-radius: 12px;
    border: 0px;
    padding: 9px 0px 12px;
    margin-top: 16px;
}

/******************** 
tablet view styles
*********************/

@media screen and (min-width: 834px) {

    .border {
        padding: 0px 80px;
    }

    /* header */

    .header {
        height: 112px;
        padding: 0px 80px;
        filter: none;
    }

    .websiteLogo {
        width: 44px;
    }

    .header > p {
        font-size: 18px;
        line-height: 28px;
    }

    .burgerMenu {
        display: none;
    }

    .headerBtn {
        width: 198px;
        background-color: var(--blue);
        color: var(--white);
        font-size: 16px;
        line-height: 24px;
        border-radius: 46px;
        padding: 16px 0px 20px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 0px 15px rgba(126, 126, 126, 0.3);

        position: initial;
    }

    .headerBtn:hover {
        background-color: var(--white);
        color: var(--blue);
    }

    /* section-head */

    .sectionHead {
        background-position: right 51px top;
        padding: 48px 80px 72px;
    }

    .pageHeadings {
        width: 674px;
        text-align: initial;
        margin-bottom: 56px;
    }

    .pageHeading {
        font-size: 48px;
        text-align: left;
        line-height: 64px;
    }

    .pageHeadings > .accent {
        line-height: 70px;
    }

    .emailInput {
        width: 411px;
    }

    .emailInput input {
        font-size: 18px;
        line-height: 28px;
        padding: 18px 14px;
        margin-bottom: 24px;
    }

    .emailInput a {
        font-size: 18px;
        line-height: 28px;
        padding: 18px;
    }

    /* section-information */

    .sectionInformation {
        padding: 64px 80px 96px;
    }

    .info {
        gap: 22px;
    }

    .info:nth-child(odd) {
        flex-direction: row-reverse;
    }

    .info:nth-child(even) {
        flex-direction: row;
    }

    .info img {
        width: 328px;
        margin-bottom: 0px;
    }

    .info h3 {
        text-align: left;
        font-size: 28px;
        line-height: 36px;
        margin-bottom: 24px;
    }

    .info p {
        text-align: left;
        font-size: 16px;
        line-height: 24px;
    }

    /* section-form */

    .sectionForm {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 48px 80px 128px;
    }

    .sectionForm > h1 {
        font-size: 48px;
        line-height: 64px;
        margin-bottom: 16px;
    }

    .sectionForm > p {
        max-width: 700px;
        font-size: 16px;
        line-height: 24px;
    }

    .brandForm {
        max-width: 740px;
        margin-top: 80px;
    }

    .brandForm > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
    }

    .brandForm label {
        color: var(--blue);
        font-size: 18px;
        font-family: var(--ff-qs);
        font-weight: var(--fw-sb);
        line-height: 28px;
        display: initial;
    }

    .brandForm input {
        max-width: 456px;
        border-width: 3px;
        padding-left: 9.11px;
    }
    
    .brandForm > div:last-child {
        justify-content: flex-end;
        margin-bottom: 0px;
        margin-top: 60px;
    }

    .brandForm > div:last-child p {
        max-width: 456px;
    }

    
    .brandForm button {
        max-width: 456px;
        font-size: 18px;
        line-height: 28px;
        align-self: flex-end;
        padding: 17px 0px 20px;
        margin-top: 0px;
    }

}

/**********
desktop styles
**********/

@media screen and (min-width: 1366px) {

    /* header */

    .headerBtn {
        font-size: 18px;
        line-height: 28px;
    }

    /* section-head */

    .sectionHead {
        background-position: right 120px top;
        background-size: 500px;
        padding: 64px 80px 72px;
    }

    .pageHeadings {
        width: 845px;
        margin-bottom: 72px;
    }

    /* section -information */

    .sectionInformation {
        padding: 64px 135px 94px;
    }
    
    .info {
        gap: 62px;
    }

    .info img {
        width: 482px;
    }

    .info h3 {
        font-size: 36px;
        line-height: 48px;
    }

    .info p {
        font-size: 18px;
        line-height: 28px;
    }

    /* section-form */

    .brandForm {
        margin-top: 84px;
    }

    .brandForm input {
        max-width: 520px;
        padding-left: 10.4px;
    }

    .brandForm > div:last-child p {
        max-width: 520px;
    }
    
    .brandForm button {
        max-width: 520px;
    }

    /*********** 
section-5 
***********/

.sectionFive {
    width: 100%;
    padding: 88px 80px 40px;
}

.sectionFive h2 {
    color: var(--blue);
    text-align: center;
    font-size: 36px;
    font-family: var(--ff-ns);
    font-weight: var(--fw-b);
}

.storeLogos {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 40px;
    margin-bottom: 88px;
}

.storeLogos > img {
    height: 60px;
}

.sectionFive h3 {
    font-size: 28px;
    font-family: var(--ns);
    font-weight: var(--fw-sb)
}

.joinOptns {
    display: flex;
    margin-top: 20px;
    margin-bottom: 65px;
}

.joinOptns > p, .joinOptns > a {
    font-size: 18px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-r);
    margin-right: 40px;
    text-decoration: underline;
}

.sectionFive > .signUpBeta {
    width: max-content;
    background-color: var(--blue);
    color: var(--white);
    font-size: 18px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-sb);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 0px 15px rgba(126, 126, 126, 0.3);
    margin: 0px;
}

.App {
    font-family: sans-serif;
    text-align: center;
  }

}