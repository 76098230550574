@import url(https://fonts.googleapis.com/css2?family=Noto+Sans:wght@500;600;700&family=Quicksand:wght@400;600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p,
h4,
button {
    padding: 0px;
    margin: 0px;
}

.SubmitPopover_container__2XKZJ {
    width: 320px;
    height: 60px;
    background-color: var(--white);
    font-family: var(--ff-qs);
    display: flex;
    align-items: center;
    grid-gap: 12px;
    gap: 12px;
    border-radius: 4px;
    border-left: 8px solid #5cf07b;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 12px;

    transition: right 300ms ease-out;

    position: fixed;
    top: 10px;
    right: -350px;
    z-index: 2;
}

.SubmitPopover_container__2XKZJ img {
    height: 35px;
}

.SubmitPopover_container__2XKZJ > div {
    margin-right: auto;
}

.SubmitPopover_container__2XKZJ h4 {
    color: #626365;
    font-size: 14px;
}

.SubmitPopover_container__2XKZJ > div > p {
    color: #828283;
    font-size: 12px;
}

.SubmitPopover_container__2XKZJ > button {
    background-color: var(--white);
    color: #828283;
    font-size: 10px;
    border: none;
    cursor: pointer;
}

/* @media screen and (max-width: 824px) {
    .container {
        width: 200px;
    }
} */
footer {
    width: 100%;
    color: var(--blue);
    font-size: 16px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-sb);
    display: flex;
    justify-content: space-between;
    padding: 45px 80px;
}

footer > div {
    display: flex;
    align-items: center;
}

.Footer_logo__2nNnI {
    width: 57px;
}

.Footer_contactInfo__8uzBx img {
    height: 24px;
}

.Footer_contactInfo__8uzBx {
    color: var(--blue);
    font-weight: var(--fw-r);
    display: flex;
    align-items: center;
    grid-gap: 80px;
    gap: 80px;
    margin-left: 53px;
}

.Footer_contactInfo__8uzBx div {
    display: flex;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
}

.Footer_socialMediaLinks__1rYbE {
    display: flex;
    grid-gap: 24px;
    gap: 24px;
    margin-right: 55px;
}

.Footer_socialMediaLinks__1rYbE img {
    height: 32px;
}

.Footer_links__1QWNh {
    display: flex;
    grid-gap: 32px;
    gap: 32px;
}

.Footer_copyrightText__-0l3d {
    width: -webkit-max-content;
    width: max-content;
    color: var(--blue);
    font-size: 16px;
    font-family: var(--ff-qs);
    margin-top: 19px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 16px;
}

/* mobile view styles */

@media screen and (max-width: 834px) {
    footer {
        flex-direction: column;
        grid-gap: 51px;
        gap: 51px;
        padding: 32px 33px 35px;
    }

    footer div {
        align-items: flex-start;
    }

    footer > div:first-child {
        flex-direction: column;
    }

    .Footer_logo__2nNnI {
        width: 46px;
    }

    .Footer_contactInfo__8uzBx {
        flex-direction: column;
        align-items: flex-start;
        grid-gap: 22px;
        gap: 22px;
        margin: 25px 0px 0px;
    }

    .Footer_contactInfo__8uzBx div {
        grid-gap: 12px;
        gap: 12px;
    }

    .Footer_socialMediaLinks__1rYbE {
        margin-right: auto;
    }

    .Footer_links__1QWNh {
        flex-direction: column;
        grid-gap: 16px;
        gap: 16px;
    }

    .Footer_copyrightText__-0l3d {
        font-size: 14px;
    }
}

/* tablet view styles */

@media screen and (min-width: 834px) and (max-width: 1365px) {
    
    footer {
        flex-direction: column;
        grid-gap: 48px;
        gap: 48px;
        padding: 47px 80px 53px;
    }

    footer > div, footer > div:first-child {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .Footer_contactInfo__8uzBx{
        flex-direction: row;
        grid-gap: 37px;
        gap: 37px;
        margin-left: 40px;
        margin-top: 0px;
    }

    .Footer_contactInfo__8uzBx img {
        height: 25px;
    }

    .Footer_socialMediaLinks__1rYbE {
        margin-right: 52px;
    }

    .Footer_links__1QWNh {
        grid-gap: 24px;
        gap: 24px;
    }
    .Footer_joinOptns__LgZFG {
        display: flex;
        margin-top: 20px;
        margin-bottom: 65px;
    }
    .Footer_btn__1Evni {
        cursor: pointer;
    }
}
html, body {
    overflow-x: hidden
}

::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--gray-two);
  opacity: 1; /* Firefox */
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--gray-two);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: var(--gray-two);
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: var(--gray-two);
}

.Home_btn__1_tw- {
    cursor: pointer;
}

.Home_border__38_CW {
    width: 100%;
    display: flex;
    justify-content: center;
}

.Home_border__38_CW > div {
    height: 1px;
    width: 92%;
    background-color: var(--gray-four);
}

/*********** 
section-1
***********/

.Home_sectionOne__EogPv {
    height: 100vh;
    min-height: 800px;
    max-height: 875px;
    background-color: var(--blue);
}

.Home_sectionReviewHeader__23XOK {
    height: 100vh;
    min-height: 100px;
    max-height: 10px;
    background-color: var(--blue);
}

.Home_header__2e4xW {
    width: 100%;
    color: var(--white);
    font-size: 18px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-sb);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 80px;
}

.Home_websiteLogo__fw5b1 {
    width: 44px;
}
.Home_productImage__38OcR {
    /* height: 100%; */
    /* position: absolute; */
    /* bottom: -175px */
    width: 39px;
    height: 39px;
}
.Home_socialMediaLinks__oUwfJ {
    display: flex;
    grid-gap: 24px;
    gap: 24px;
    margin-right: 55px;
}

.Home_socialMediaLinks__oUwfJ img {
    height: 32px;
}
.Home_burgerMenu__1b8gF{
    height: 24px;
    transition: -webkit-transform 200ms ease-out;
    transition: transform 200ms ease-out;
    transition: transform 200ms ease-out, -webkit-transform 200ms ease-out;
}

.Home_headerBtns__2gO1w {
    display: flex;
    align-items: center;
    z-index: 2;
}

.Home_headerBtns__2gO1w > div {
    position: relative;
}

.Home_addProduct__Uup7U {
    cursor: pointer;
    font-size: 16px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-sb);
    margin-right: 24px;
}

.Home_addProduct__Uup7U:hover {
    text-decoration: underline;
}

.Home_partner__3SkVT {
    font-size: 16px;
}

.Home_partner__3SkVT:hover {
    text-decoration: underline;
}

.Home_partnerOptns__3BlH5 {
    width: -webkit-max-content;
    width: max-content;
    height: 90px;
    background-color: rgba(25, 52, 88, 0.9);
    color: var(--gray-four);
    font-size: 16px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-r);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 4px;
    padding: 8px 18px;
    -webkit-transform: scale(1, 0);
            transform: scale(1, 0);
    -webkit-transform-origin: top;
            transform-origin: top;
    transition: -webkit-transform 200ms ease-in;
    transition: transform 200ms ease-in;
    transition: transform 200ms ease-in, -webkit-transform 200ms ease-in;

    position: absolute;
    left: -10px;
}

.Home_partnerOptns__3BlH5 > p:hover, .Home_partnerOptns__3BlH5 > a:hover {
    text-decoration: underline;
}

.Home_partnerOptns__3BlH5 > .Home_border__38_CW > div {
    background-color: var(--gray-one);
}

.Home_headerBtns__2gO1w > div:hover > .Home_partnerOptns__3BlH5 {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
}

.Home_signUpBeta__2o2YX {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 0px 15px rgba(126, 126, 126, 0.3);
    border-radius: 46px;
    padding: 16px 26.5px;
    margin-left: 24px;
    transition: background-color 200ms ease-in, color 200ms ease-in;
}

.Home_headerBtns__2gO1w .Home_signUpBeta__2o2YX:hover {
    background-color: var(--white);
    color: var(--blue);
}

.Home_burgerMenu__1b8gF, .Home_headerBtns__2gO1w > .Home_border__38_CW {
    display: none;
}

.Home_sectionOne__EogPv h1 {
    font-size: 48px;
    font-family: var(--ff-ns);
    font-weight: var(--fw-b);
    margin: 0px 32px;
}

.Home_sectionOne__EogPv h1:first-child {
    color: var(--white);
    text-align: center;
}

.Home_headBody__134pQ {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 64px;
}
.Home_headBody2__2rzun {
    color: var(--white);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 64px;
}

.Home_headBody__134pQ h1:last-child {
    margin-top: 8px;
}

.Home_accentH1__aMPr3 {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    color: var(--white);
    text-align: center;
    padding: 0px 4px 8px;
    margin-top: 8px;
    margin-bottom: 24px;

    position: relative;
    background-image: 
        linear-gradient(#fff, #fff),
        linear-gradient(#fff,#fff);
    -webkit-background-clip:
        padding-box,
        text;
    background-clip:
        padding-box,
        text;
    background-size: 0% 100%;
    background-repeat: no-repeat;
    background-position: left;
    -webkit-animation: Home_revealer-text__1zola 0.8s 1s forwards;
            animation: Home_revealer-text__1zola 0.8s 1s forwards;
    -webkit-animation-delay: 4000ms;
            animation-delay: 4000ms;
    z-index: 1;
}

@-webkit-keyframes Home_revealer-text__1zola {
  0% {
    background-size: 0% 100%;
    color: var(--white);
  }
  100% {
    background-size: 100% 100%;
    background-position: left;
    color: var(--blue);
  }
}

@keyframes Home_revealer-text__1zola {
  0% {
    background-size: 0% 100%;
    color: var(--white);
  }
  100% {
    background-size: 100% 100%;
    background-position: left;
    color: var(--blue);
  }
}

.Home_headBody__134pQ > div {
    height: 650px;
    margin-top: 32px;
}

.Home_mockup___1UYM {
    height: 100%;
    /* position: absolute; */
    /* bottom: -175px */
}

/***********
section-2 
***********/

.Home_sectionTwo__2tnEA {
    height: 536px;
    color: var(--blue);
    font-size: 18px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-r);
    display: flex;
    align-items: flex-end;
    padding-bottom: 44px;
}

.Home_infos__5xRif {
    width: 100%;
    display: flex;
    grid-gap: 64px;
    gap: 64px;
    margin: 0 80px;
}

.Home_infos__5xRif > div {
    width: 50%;
    display: flex;
    justify-content: space-around;
    grid-gap: 24px;
    gap: 24px;
}

.Home_info__2icpo {
    width: 35%;
    min-width: 136px;
    max-width: 272px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Home_infoIcon__EF9uT {
    width: 64px;
    height: 64px;
    /* margin-bottom: 16px; */
}

/*********** 
section-3
***********/

.Home_sectionThree__3fkaG {
    width: 100%;
    background-image: url(/static/media/sec-bg.144ade57.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 850px;
    background-color: var(--blue);
    padding-top: 178px;
    padding-bottom: 98px;
    padding-left: 190px;
    padding-right: 190px;
}

.Home_sectionThree__3fkaG > div {
    width: 100%;
    display: flex;
    /* justify-content: space-around; */
    align-items: center;
    grid-gap: 108px;
    gap: 108px;
    margin-bottom: 8px;
}

.Home_sectionThree__3fkaG > div:nth-child(2) {
    justify-content: flex-end;
}

.Home_sectionThree__3fkaG h2 {
    color: var(--blue-four);
    font-size: 36px;
    font-family: var(--ff-ns);
    font-weight: var(--fw-sb);
}

.Home_sectionThree__3fkaG p {
    color: var(--blue-four);
    font-size: 18px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-r);
    line-height: 28px;
    margin-top: 24px;
}

.Home_sectionThree__3fkaG img {
    width: 325px;
    /* margin: 0px 32px; */
}


/*********** 
section-4
***********/

.Home_sectionFour__3xJjU {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;
    padding-bottom: 41px;
}

.Home_sectionFour__3xJjU > h1 {
    color: var(--blue);
    text-align: center;
    font-size: 48px;
    font-family: var(--ff-ns);
    font-weight: var(--fw-b);
    margin-bottom: 16px;
}

.Home_sectionFour__3xJjU > p {
    color: var(--black);
    text-align: center;
    font-size: 16px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-r);
    margin-bottom: 28px;
}

.Home_sectionFour__3xJjU > .Home_border__38_CW div {
    width: 40%;
}

 .Home_signedUp__1wie5 {
    color: var(--blue-impt);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 76px;
    margin-bottom: 90px;
}

.Home_signedUp__1wie5 > img {
    width: 100px;
}

.Home_signedUp__1wie5 > h3 {
    font-size: 28px;
    font-family: var(--ff-ns);
    font-weight: var(--fw-sb);
    line-height: 36px;
    margin-top: 26px;
    margin-bottom: 12px;
}

.Home_signedUp__1wie5 > p {
    font-size: 16px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-r);
    line-height: 24px;
}

.Home_sectionFour__3xJjU > .Home_border__38_CW > div {
    width: 100%;
    min-width: 670px;
    max-width: 750px;
}

.Home_betaForm__1VozR {
    width: 100%;
    min-width: 670px;
    max-width: 750px;
    margin-top: 47px;
}

.Home_betaForm__1VozR > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}

.Home_betaForm__1VozR label {
    color: var(--blue);
    font-size: 18px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-sb);
}

.Home_betaForm__1VozR input {
    width: 100%;
    color: var(--blue);
    max-width: 518px;
    font-size: 16px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-r);
    border: 3px solid var(--blue-two);
    border-radius: 5px;
    padding: 10px;
}

.Home_betaForm__1VozR input:focus {
    outline: 0;
}

.Home_betaForm__1VozR div:nth-child(3) {
    margin-bottom: 16px;
}

.Home_betaForm__1VozR div:nth-child(4) p {
    width: 100%;
    max-width: 518px;
    color: var(--red);
    font-size: 14px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-r);
}

.Home_betaForm__1VozR div:nth-child(4) {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}

.Home_betaForm__1VozR .Home_signUpBeta__2o2YX {
    width: 100%;
    max-width: 520px;
    background-color: var(--blue);
    color: var(--white);
    line-height: 28px;
    font-size: 18px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-sb);
    justify-content: center;
    padding: 17px 0px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 0px 15px rgba(126, 126, 126, 0.3);
    border: 0;
    border-radius: 12px;
    margin-top: 32px;
}

/*********** 
section-5 
***********/

.Home_sectionFive__EZE4n {
    width: 100%;
    padding: 88px 80px 40px;
}

.Home_sectionFive__EZE4n h2 {
    color: var(--blue);
    text-align: center;
    font-size: 36px;
    font-family: var(--ff-ns);
    font-weight: var(--fw-b);
}

.Home_storeLogos__2iiqz {
    width: 100%;
    display: flex;
    justify-content: center;
    grid-gap: 15px;
    gap: 15px;
    margin-top: 40px;
    margin-bottom: 88px;
}

.Home_storeLogos__2iiqz > img {
    height: 60px;
}

.Home_sectionFive__EZE4n h3 {
    font-size: 28px;
    font-family: var(--ns);
    font-weight: var(--fw-sb)
}

.Home_joinOptns__2p09s {
    display: flex;
    margin-top: 20px;
    margin-bottom: 65px;
}

.Home_joinOptns__2p09s > p, .Home_joinOptns__2p09s > a {
    font-size: 18px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-r);
    margin-right: 40px;
    text-decoration: underline;
}

.Home_sectionFive__EZE4n > .Home_signUpBeta__2o2YX {
    width: -webkit-max-content;
    width: max-content;
    background-color: var(--blue);
    color: var(--white);
    font-size: 18px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-sb);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 0px 15px rgba(126, 126, 126, 0.3);
    margin: 0px;
}

/******************  
tablet view styles
******************/

@media screen and (min-width: 834px) and (max-width: 1365px) {

    .Home_border__38_CW {
        padding: 0px 80px;
    }

    .Home_border__38_CW > div {
        width: 100%;
    }

    /* section -1 */

    .Home_headBody__134pQ {
        margin: 64px 80px;
    }

    .Home_partnerOptns__3BlH5 .Home_border__38_CW {
        padding: 0px 0px;
    }

    /* section-2 */

    .Home_sectionTwo__2tnEA {
        /* height: 828px; */
        padding: 44px 80px; 
    }

    .Home_infos__5xRif {
        /* flex-direction: column; */
        margin: 0px;
    }

    .Home_infos__5xRif > div {
        width: 100%;
        justify-content: space-between;
        grid-gap: 64px;
        gap: 64px;
    }

    .Home_info__2icpo {
        width: 100%;
        max-width: 272px;
        font-size: 16px;
        line-height: 24px;
    }

    .Home_info__2icpo > p {
        margin-top: 24px;
    }

    /* section-3 */

    .Home_sectionThree__3fkaG {
        background-size: 700px;
        padding: 178px 80px 98px;
    }

    .Home_sectionThree__3fkaG > div {
        grid-gap: 24px;
        gap: 24px;
    }

    .Home_sectionThree__3fkaG h2 {
        font-size: 28px;
        line-height: 36px;
    }

    /* section-4 */ 

    .Home_sectionFour__3xJjU {
        padding: 98px 80px 40px;
    }

    .Home_sectionFour__3xJjU > .Home_border__38_CW {
        padding: 0px 0px;
    }
    
}


/******************  
mobile view styles
******************/

@media screen and (max-width: 833px) {

    body {
        background-color: var(--white);
    }

    .Home_border__38_CW > div {
        width: 85%;
    }

    /****** section-1 ******/

    .Home_sectionOne__EogPv {
        max-height: 600px;
        min-height: 500px;
        padding: 0px 32px;
    }

    .Home_sectionReviewHeader__23XOK {
        min-height: 100px;
        max-height: 10px;
        padding: 0px 32px;
    }

    .Home_header__2e4xW {
        padding: 10px 0px;
    }

    .Home_websiteLogo__fw5b1 {
        width: 39px;
    }

    .Home_burgerMenu__1b8gF, .Home_headerBtns__2gO1w > .Home_border__38_CW {
        display: initial;
    }

    .Home_menu__2vHRf {
        position: relative;
    }

    .Home_headerBtns__2gO1w {
        width: 140px;
        height: 200px;
        background-color: var(--blue);
        
        flex-direction: column-reverse;
        justify-content: space-between;
        align-items: center;
        border-radius: 8px;
        box-shadow: 0px 0px 10px rgba(252, 252, 252, 0.5);

        position: absolute;
        right: 0;

        -webkit-transform-origin: top;

                transform-origin: top;
        transition: -webkit-transform 200ms ease-in;
        transition: transform 200ms ease-in;
        transition: transform 200ms ease-in, -webkit-transform 200ms ease-in;
    }

    .Home_partnerOptns__3BlH5 > .Home_border__38_CW > div {
        background-color: var(--blue-one);
        margin: 4.78px 0px 3.22px;
    }

    .Home_headerBtns__2gO1w > div:first-child {
        width: 100%;
    }

    .Home_headerBtns__2gO1w > .Home_signUpBeta__2o2YX {
        font-size: 14px;
        font-family: var(--ff-qs);
        font-weight: var(--fw-r);
        border-radius: 0px;
        box-shadow: none;
        padding: 0px;
        margin: 8px 0px 9.11px;
        transition: none;
    }

    .Home_headerBtns__2gO1w .Home_signUpBeta__2o2YX:hover {
        background-color: var(--blue);
        color: var(--white)
    }

    .Home_headerBtns__2gO1w > .Home_border__38_CW > div {
        width: 140px;
        background-color: var(--blue-one);
    }

    .Home_addProduct__Uup7U {
        text-align: center;
        font-size: 14px;
        font-weight: var(--fw-r);
        padding: 8px 0px;
        margin-right: 0px;
        border-top: 1px solid var(--blue-one);
    }

    .Home_partner__3SkVT {
        text-align: center;
        font-size: 14px;
        font-family: var(--ff-qs);
        font-weight: var(--fw-r);
        padding: 8px 0px 12px;
    }

    .Home_partnerOptns__3BlH5 {
        height: -webkit-min-content;
        height: min-content;
        width: 100%;
        background-color: rgba(0,0,0,0);
        color: var(--gray-three);
        font-size: 14px;
        font-family: var(--ff-qs);
        font-weight: var(--fw-r);
        align-items: flex-end;
        border-radius: 0px;
        padding: 0px;
        margin: 0px 0px 15.44px;
        padding-right: 10.36px;
        -webkit-transform: scale(1,1);
                transform: scale(1,1);

        position: initial;
    }

    .Home_partnerOptns__3BlH5 .Home_border__38_CW {
        justify-content: flex-end;
    }

    .Home_partnerOptns__3BlH5 .Home_border__38_CW > div {
        width: 92px;
    }

    .Home_headBody__134pQ {
        margin: 0px;
        padding-top: 40px;
    }

    .Home_headBody__134pQ h1 {
        font-size: 28px;
        line-height: 36px;
        margin: 0px;
    }

    .Home_headBody__134pQ > div {
        max-height: 525px;
        /* height: 80vh; */
        min-height: 400px;
        /* width: 100%; */
        /* max-width: 500px; */
        padding: 0px 32px;
    }

    /****** section-2 ******/

    .Home_sectionTwo__2tnEA {
        height: 618px;
        padding: 32px;
    }

    .Home_infos__5xRif {
        flex-direction: row;
        grid-gap: 24px;
        gap: 24px;
        margin: 0px;
        flex-wrap: nowrap;
    }

    .Home_infos__5xRif > div {
        width: 100%;
        flex-direction: column;
        justify-content: flex-start;
        grid-gap: 32px;
        gap: 32px;
    }

    .Home_infos__5xRif > div:last-child {
        align-items: flex-end;
    }

    .Home_info__2icpo {
        width: 100%;
        min-height: 120px;
        min-width: 0px;
        font-size: 16px;
        line-height: 24px;
    }

    .Home_infoIcon__EF9uT {
        height: 40px;
        width: 40px;
        margin-bottom: 16px;
    }

    /****** section-3 ******/

    .Home_sectionThree__3fkaG {
        background-image: url(/static/media/sec-bg-mobile.7cf57ed1.png);
        background-size: 300px;
        padding: 35px 32px 58px;
    }

    .Home_sectionThree__3fkaG  h2 {
        font-size: 20px;
        line-height: 28px;
    }

    .Home_sectionThree__3fkaG p {
        font-size: 14px;
        margin-top: 16px;
    }

    .Home_sectionThree__3fkaG > div {
        flex-direction: column;
        align-items: flex-start;
        grid-gap: 24px;
        gap: 24px;
        margin-bottom: 64px;
    }

    .Home_sectionThree__3fkaG > div:nth-child(2) {
        flex-direction: column-reverse;
        align-items: flex-end;
    }

    .Home_sectionThree__3fkaG img {
        width: 216px;
    }

    /****** section-4 ******/
    
    .Home_sectionFour__3xJjU {
        padding: 32px;
    }

    .Home_sectionFour__3xJjU > h1 {
        font-size: 28px;
        line-height: 36px;
        margin-top: 32px;
        margin-bottom: 8px;
    }

    .Home_sectionFour__3xJjU > p {
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 16px;
    }

    .Home_sectionFour__3xJjU .Home_border__38_CW div {
        width: 100%;
    }

    .Home_sectionFour__3xJjU > .Home_border__38_CW {
        padding: 0px 0px;
    }

    .Home_sectionFour__3xJjU > .Home_border__38_CW > div {
        width: 100%;
        min-width: 0px;
    }

    .Home_betaForm__1VozR {
        min-width: 0px;
        margin-top: 24px;
    }

    .Home_betaForm__1VozR label {
        display: none;
    }

    .Home_betaForm__1VozR input {
        width: 100%;
        max-width: none;
        font-size: 14px;
        line-height: 20px;
        border-width: 1px;
        padding: 12px 6px;
        border-width: 1px;
    }

    .Home_betaForm__1VozR .Home_signUpBeta__2o2YX {
        min-width: 100%;
    }

    /****** section-5 ******/

    .Home_sectionFive__EZE4n {
        padding: 64px 32px 24px;
    }

    .Home_sectionFive__EZE4n > h2 {
        font-size: 20px;
        line-height: 28px;
    }

    .Home_storeLogos__2iiqz {
        margin-bottom: 68px;
    }

    .Home_storeLogos__2iiqz img {
        height: 39px;
    }

    .Home_sectionFive__EZE4n h3 {
        font-size: 18px;
        line-height: 24px;
    }

    .Home_joinOptns__2p09s {
        margin-top: 12px;
        margin-bottom: 40px;
    }

    .Home_joinOptns__2p09s > p, .Home_joinOptns__2p09s > a {
        font-size: 14px;
        line-height: 20px;
    }

    .Home_sectionFive__EZE4n > .Home_signUpBeta__2o2YX {
        font-size: 14px;
        line-height: 20px;
        padding: 12.5px 15px;
    }

}
::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--gray-two);
  opacity: 1; /* Firefox */
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--gray-two);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: var(--gray-two);
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: var(--gray-two);
}

.join-as-expert_body__3HP7D {
    background-color: var(--white);
}

.join-as-expert_border__3d5S0 {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0px 32px;
}

.join-as-expert_border__3d5S0 > div {
    height: 1px;
    width: 100%;
    background-color: var(--gray-four);
}

.join-as-expert_btn__2otuz {
    cursor: pointer;
}

/* header */

.join-as-expert_header__2CxfU {
    width: 100%;
    background-color: var(--white);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 32px;
    -webkit-filter: drop-shadow(0px 6px 15px rgba(126, 126, 126, 0.25));
            filter: drop-shadow(0px 6px 15px rgba(126, 126, 126, 0.25));
}

.join-as-expert_websiteLogo__19z9y {
    width: 37px;
}

.join-as-expert_header__2CxfU > p {
    color: var(--blue);
    font-size: 16px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-sb);
    line-height: 24px;
}

.join-as-expert_burgerMenu__Nxhtm {
    height: 24px;
    transition: -webkit-transform 200ms ease-out;
    transition: transform 200ms ease-out;
    transition: transform 200ms ease-out, -webkit-transform 200ms ease-out;
}

.join-as-expert_headerBtn__2q8ZX {
    width: 140px;
    background-color: var(--blue);
    color: var(--white);
    text-align: center;
    font-size: 14px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-sb);
    line-height: 20px;
    border-radius: 8px;
    padding: 6px 0;
    -webkit-transform: scale(1, 0);
            transform: scale(1, 0);
    -webkit-transform-origin: top;
            transform-origin: top;
    transition: -webkit-transform 200ms ease-in;
    transition: transform 200ms ease-in;
    transition: transform 200ms ease-in, -webkit-transform 200ms ease-in;

    position: absolute;
    right: 32px;
    bottom: -35px;
}

.join-as-expert_pageHeadings__4NAs- {
    text-align: center;
    margin: 40px 32px 24px;
}

.join-as-expert_pageHeading__zkhmE {
    color: var(--blue);
    text-align: center;
    font-size: 28px;
    font-family: var(--ff-ns);
    font-weight: var(--fw-b);
    line-height: 36px;
}

.join-as-expert_pageHeadings__4NAs- > .join-as-expert_accent__J5ZmK {
    background-color: var(--blue);
    color: var(--blue);
    padding: 0px 5px;
    margin: 1px 0px;
    line-height: 42px;

    position: relative;
    background-image: 
        linear-gradient(var(--blue), var(--blue)),
        linear-gradient(#fff,#fff);
    -webkit-background-clip:
        padding-box,
        text;
    background-clip:
        padding-box,
        text;
    background-size: 0% 100%;
    background-repeat: no-repeat;
    background-position: left;
    -webkit-animation: join-as-expert_revealer-text__2MEw7 0.8s 1s forwards;
            animation: join-as-expert_revealer-text__2MEw7 0.8s 1s forwards;
    -webkit-animation-delay: 4500ms;
            animation-delay: 4500ms;
}

@-webkit-keyframes join-as-expert_revealer-text__2MEw7 {
  0% {
    background-size: 0% 100%;
    color: var(--blue);
  }
  100% {
    background-size: 100% 100%;
    background-position: left;
    color: var(--white);
  }
}

@keyframes join-as-expert_revealer-text__2MEw7 {
  0% {
    background-size: 0% 100%;
    color: var(--blue);
  }
  100% {
    background-size: 100% 100%;
    background-position: left;
    color: var(--white);
  }
}

/*********** 
section-info
***********/

.join-as-expert_sectionInfo__1WRQl {
    width: 100%;
    background-image: url(/static/media/background-image.0f6094be.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 32px;
}

.join-as-expert_infoBox__3MUYF {
    width: 100%;
    max-width: 350px;
    min-height: 268px;
    aspect-ratio: 1/1;
    background-color: rgba(1, 30, 70, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 17px;
    margin-bottom: 16px;
}

.join-as-expert_infoBox__3MUYF:last-child {
    margin-bottom: 0px;
}

.join-as-expert_infoBox__3MUYF > img {
    height: 64px;
    margin-bottom: 24px;
}

.join-as-expert_infoBox__3MUYF > h4 {
    color: var(--gray-two);
    font-size: 16px;
    font-family: var(--ff-ns);
    font-weight: var(--fw-sb);
    line-height: 20px;
    text-align: center;
}

.join-as-expert_infoBox__3MUYF > h4 > span {
    color: var(--white);
}

/*********** 
section-form
***********/

.join-as-expert_sectionForm__9zghr {
    padding: 64px 32px 88px;
}

.join-as-expert_sectionForm__9zghr > h1 {
    color: var(--blue);
    font-size: 28px;
    font-family: var(--ff-ns);
    font-weight: var(--fw-b);
    line-height: 36px;
    text-align: center;
}

.join-as-expert_sectionForm__9zghr > p {
    color: var(--black);
    font-size: 12px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-r);
    line-height: 16px;
    text-align: center;
    margin-top: 8px;
}

.join-as-expert_signedUp__yjxr_ {
    color: var(--blue-impt);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 76px;
    margin-bottom: 90px;
}

.join-as-expert_signedUp__yjxr_ > img {
    width: 100px;
}

.join-as-expert_signedUp__yjxr_ > h3 {
    font-size: 28px;
    font-family: var(--ff-ns);
    font-weight: var(--fw-sb);
    line-height: 36px;
    margin-top: 26px;
    margin-bottom: 12px;
}
.join-as-expert_signedUp__yjxr_ > p {
    font-size: 16px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-r);
    line-height: 24px;
}

.join-as-expert_expertForm__3JA9e {
    margin-top: 40px;
}

.join-as-expert_expertForm__3JA9e > div {
    margin-bottom: 16px;
}

.join-as-expert_expertForm__3JA9e label {
    color: var(--blue);
    font-family: var(--ff-qs);
    font-weight: var(--fw-sb);
    display: none;
}

.join-as-expert_expertForm__3JA9e input, 
.join-as-expert_expertForm__3JA9e select {
    width: 100%;
    background-color: var(--white);
    color: var(--blue);
    font-size: 14px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-r);
    border: 1px solid var(--blue-two);
    border-radius: 5px;
    padding: 12px 6px;
}

.join-as-expert_expertForm__3JA9e input:focus, 
.join-as-expert_expertForm__3JA9e select {
    outline: 0;
}

.join-as-expert_addLinks__3Kb6A {
    color: var(--gray-one);
    font-size: 16px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-sb);
    line-height: 24px;
    margin: 32px 0px 24px;
}

.join-as-expert_addLinks__3Kb6A > div:first-child > p {
    margin-bottom: 24px;
}

.join-as-expert_addLinks__3Kb6A > div:first-child > p > span {
    color: var(--gray-one);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}

.join-as-expert_addLinks__3Kb6A > div {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.join-as-expert_addLinks__3Kb6A img {
    height: 32px;
    width: 32px;
    margin-right: 24px;
}

.join-as-expert_addLinks__3Kb6A > div:first-child {
    justify-content: center;
    margin: 0px;
}

.join-as-expert_addLinks__3Kb6A > div:first-child > img {
    display: none;
}

.join-as-expert_addLink__FgA-3 {
    margin-top: 8px;
}

.join-as-expert_addLink__FgA-3 img {
    height: 20px;
    margin-right: 8px;
}

.join-as-expert_addLink__FgA-3 p {
    color: var(--blue-three);
    font-size: 14px;
    font-family: var(--ff-ns);
    font-weight: var(--fw-m);
    line-height: 19px;
}

.join-as-expert_expertForm__3JA9e > div:nth-last-child(1) {
    display: initial;
}

.join-as-expert_expertForm__3JA9e > div:nth-last-child(1) p {
    color: var(--red);
    font-size: 14px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-r);
    margin-bottom: 32px;
}

.join-as-expert_submitBtn__2HdZP {
    width: 100%;
    background-color: var(--gray-one);
    color: var(--white);
    font-size: 16px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-sb);
    line-height: 24px;
    border: none;
    border-radius: 12px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 0px 15px rgba(126, 126, 126, 0.3);
    padding: 12px 0px;
    margin-top: 32px;
}

/***************
Tablet View styles
***************/

@media screen and (min-width: 834px) {

    /* header */

    .join-as-expert_header__2CxfU {
        height: 64px;
        padding: 0px 80px;
    }

    .join-as-expert_websiteLogo__19z9y {
        width: 44px;
    }

    .join-as-expert_header__2CxfU > p {
        font-size: 18px;
        line-height: 28px;
    }

    .join-as-expert_burgerMenu__Nxhtm {
        display: none;
    }

    .join-as-expert_headerBtn__2q8ZX {
        width: 196px;
        background-color: var(--blue);
        color: var(--white);
        font-size: 16px;
        line-height: 24px;
        border-radius: 46px;
        padding: 8px 0px;

        position: initial;
    }

    /* .headerBtn:hover {
        background-color: var(--white);
        color: var(--blue);
    } */

    .join-as-expert_pageHeadings__4NAs- {
        text-align: center;
        margin: 64px 80px 96px;
    }

    .join-as-expert_pageHeading__zkhmE {
        /* width: min-content; */
        font-size: 36px;
        line-height: 48px;
    }

    /* section-info */

    .join-as-expert_sectionInfo__1WRQl {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        grid-gap: 24px;
        gap: 24px;
        padding: 0px 80px;
    }

    .join-as-expert_infoBox__3MUYF {
        height: 293px;
        width: 325px;
        padding: 47px 24px;
        margin: 0px;
    }

    .join-as-expert_infoBox__3MUYF > img {
        height: 76px;
    }

    .join-as-expert_infoBox__3MUYF > h4 {
        font-size: 20px;
        line-height: 28px;
    }

    /* section-form */

    .join-as-expert_sectionForm__9zghr {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 76px 80px 128px;
    }

    .join-as-expert_sectionForm__9zghr > h1 {
        font-size: 48px;
        line-height: 64px;
    }

    .join-as-expert_sectionForm__9zghr > p {
        width: 60%;
        font-size: 16px;
        line-height: 24px;
        margin-top: 14px;
    }

    .join-as-expert_expertForm__3JA9e {
        width: 100%;
        max-width: 750px;
        margin-top: 70px;
    }

    .join-as-expert_expertForm__3JA9e div {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
    }

    .join-as-expert_expertForm__3JA9e > div:nth-last-child(2) {
        flex-direction: column;
    }

    .join-as-expert_expertForm__3JA9e label {
        font-size: 18px;
        line-height: 28px;
        display: initial;
    }

    .join-as-expert_expertForm__3JA9e input, 
    .join-as-expert_expertForm__3JA9e select,
    .join-as-expert_addLinks__3Kb6A > div:first-child > p {
        width: 65%;
        max-width: 475px;
        font-size: 16px;
        line-height: 24px;
        border-width: 3px;
        padding: 9px;
    }

    .join-as-expert_addLinks__3Kb6A {
        margin: 64px 0px;
    }

    .join-as-expert_addLinks__3Kb6A > div:first-child > p {
        font-size: 24px;
        line-height: 32px;
        padding: 0px;
        margin-bottom: 34px;
    }

    .join-as-expert_addLinks__3Kb6A > div:first-child > p > span {
        font-size: 14px;
        line-height: 16px;
    }

    .join-as-expert_addLinks__3Kb6A > div {
        margin-bottom: 26px;
    }

    .join-as-expert_addLinks__3Kb6A > div:first-child {
        justify-content: space-between;
    }

    .join-as-expert_addLinks__3Kb6A > div:first-child > img {
        display: initial;
    }

    .join-as-expert_addLinks__3Kb6A > div:nth-last-child(1) {
        justify-content: flex-start;
        margin-bottom: 0px;
    }

    .join-as-expert_addLinks__3Kb6A img {
        height: 47px;
        width: 47px;
    }

    .join-as-expert_addLinks__3Kb6A > div:first-child {
        margin: 0px;
    }

    .join-as-expert_addLink__FgA-3 img {
        height: 24px;
    }

    .join-as-expert_addLink__FgA-3 p {
        font-size: 16px;
        line-height: 22px;
    }

    .join-as-expert_submitBtn__2HdZP {
        padding: 17px 0px 20px;
        margin-top: 21px;
    }

    .join-as-expert_border__3d5S0 > div {
        width: 85%;
    }

}


/***************
Desktop View styles
***************/

@media screen and (min-width: 1366px) {

    /* header */

    .join-as-expert_pageHeadings__4NAs- {
        margin-right: 200px;
        margin-left: 200px;
    }

    .join-as-expert_pageHeading__zkhmE {
        /* width: min-content; */
        font-size: 36px;
        line-height: 55px;
    }

    /* section-info */
    
    .join-as-expert_sectionInfo__1WRQl {
        height: 372px;
        display: flex;
        justify-content: space-around;
    }

    .join-as-expert_infoBox__3MUYF {
        height: 100%;
        width: 30%;
        max-width: 500px;
        justify-content: center;
        padding: 0px 29px;
    }

    .join-as-expert_infoBox__3MUYF > img {
        height: 96px;
        margin-bottom: 32px;
    }

    /* section-form */

    .join-as-expert_sectionForm__9zghr {
        padding-right: 0px;
        padding-left: 0px;
    }

    .join-as-expert_expertForm__3JA9e {
        width: 60%;
        margin-top: 74px;
    }

    .join-as-expert_expertForm__3JA9e input, 
    .join-as-expert_expertForm__3JA9e select,
    .join-as-expert_addLinks__3Kb6A > div:first-child > p {
        width: 100%;
        max-width: 518px;
        padding: 9px 10px;
    }

    .join-as-expert_addLinks__3Kb6A {
        margin: 65px 0px;
    }

    .join-as-expert_addLinks__3Kb6A > div:first-child > p {
        padding: 0px 0px;
        margin-bottom: 32px;
    }

    .join-as-expert_addLinks__3Kb6A > div:nth-last-child(2) {
        margin-bottom: 33px;
    }

    .join-as-expert_addLinks__3Kb6A > div:nth-last-child(1) {
        margin-bottom: 20px;
    }

    .join-as-expert_expertForm__3JA9e > div:nth-last-child(1) p {
        margin-bottom: 32px;
    }

    .join-as-expert_border__3d5S0 > div {
        width: 95%;
    }
}
::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--gray-one);
  opacity: 1; /* Firefox */
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--gray-one);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: var(--gray-one);
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: var(--gray-one);
}

input:focus {
    outline: 0;
}

.join-as-brand_body__1z-3P {
    background-color: var(--white);
}

.join-as-brand_border__OxEsF {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0px 32px;
}

.join-as-brand_border__OxEsF > div {
    height: 1px;
    width: 100%;
    background-color: var(--gray-four);
}

.join-as-brand_btn__1z0-q {
    cursor: pointer;
}

/* header */

.join-as-brand_header__yHQgE {
    width: 100%;
    background-color: var(--blue);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 32px;
    -webkit-filter: drop-shadow(0px 6px 15px rgba(126, 126, 126, 0));
            filter: drop-shadow(0px 6px 15px rgba(126, 126, 126, 0));
}

.join-as-brand_websiteLogo__2OaRh {
    width: 39px;
}

.join-as-brand_header__yHQgE > p {
    color: var(--white);
    font-size: 16px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-sb);
    line-height: 24px;
}

.join-as-brand_burgerMenu__1pkYk {
    height: 24px;
    transition: -webkit-transform 200ms ease-out;
    transition: transform 200ms ease-out;
    transition: transform 200ms ease-out, -webkit-transform 200ms ease-out;
}

.join-as-brand_headerBtn__1XxFY {
    background-color: var(--blue);
    color: var(--white);
    text-align: center;
    font-size: 14px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-sb);
    line-height: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(252, 252, 252, 0.5);
    padding: 8.5px 25px;
    -webkit-transform: scale(1, 0);
            transform: scale(1, 0);
    -webkit-transform-origin: top;
            transform-origin: top;
    transition: -webkit-transform 200ms ease-in;
    transition: transform 200ms ease-in;
    transition: transform 200ms ease-in, -webkit-transform 200ms ease-in;

    position: absolute;
    right: 32px;
    bottom: -40px;
}

/* section-head */

.join-as-brand_sectionHead__wusQC {
    background-color: var(--blue);
    background-image: url(/static/media/bg-brand.254b1377.svg);
    background-repeat: no-repeat;
    background-position: right 30px top;
    padding: 40px 32px 48px;
}

.join-as-brand_pageHeadings__3PujB {
    text-align: center;
    margin-bottom: 48px;
}

.join-as-brand_pageHeading__27MeN {
    color: var(--white);
    font-size: 28px;
    font-family: var(--ff-ns);
    font-weight: var(--fw-b);
    line-height: 36px;
}

.join-as-brand_pageHeadings__3PujB > .join-as-brand_accent__2S29S {
    color: var(--white);
    padding: 0px 5px;
    margin: 1px 0px;
    line-height: 42px;

    position: relative;
    background-image: 
        linear-gradient(#fff, #fff),
        linear-gradient(#fff,#fff);
    -webkit-background-clip:
        padding-box,
        text;
    background-clip:
        padding-box,
        text;
    background-size: 0% 100%;
    background-repeat: no-repeat;
    background-position: left;
    -webkit-animation: join-as-brand_revealer-text__3w2pt 0.8s 1s forwards;
            animation: join-as-brand_revealer-text__3w2pt 0.8s 1s forwards;
    -webkit-animation-delay: 3800ms;
            animation-delay: 3800ms;
}

@-webkit-keyframes join-as-brand_revealer-text__3w2pt {
  0% {
    background-size: 0% 100%;
    color: var(--white);
  }
  100% {
    background-size: 100% 100%;
    background-position: left;
    color: var(--blue);
  }
}

@keyframes join-as-brand_revealer-text__3w2pt {
  0% {
    background-size: 0% 100%;
    color: var(--white);
  }
  100% {
    background-size: 100% 100%;
    background-position: left;
    color: var(--blue);
  }
}

.join-as-brand_emailInput__AG-4I,
.join-as-brand_emailInput__AG-4I input,
.join-as-brand_emailInput__AG-4I a{
    width: 100%;
}

.join-as-brand_emailInput__AG-4I input {
    background-color: var(--blue-five);
    color: var(--white);
    font-size: 16px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-r);
    line-height: 24px;
    border: 2px solid var(--gray-one);
    padding: 10.5px 12.32px;
    margin-bottom: 16px;
}

.join-as-brand_emailInput__AG-4I a {
    background-color: var(--blue-impt);
    color: var(--white);
    text-align: center;
    font-size: 16px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-sb);
    line-height: 24px;
    display: block;
    border: 0px;
    border-radius: 8px;
    padding: 7.46px;
}

/* section-information */

.join-as-brand_sectionInformation__2eI5C {
    padding: 32px 32px 48px;
}

.join-as-brand_accentBlueImpt__KYf9J {
    color: var(--blue-impt);
}

.join-as-brand_accentBlue__2aZJu {
    color: var(--blue-five);
    font-weight: var(--fw-sb);
}

.join-as-brand_info__d_g7y {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 32px;
}

.join-as-brand_info__d_g7y img {
    width: 218px;
    margin-bottom: 16px;
}

.join-as-brand_info__d_g7y h3 {
    color: var(--blue);
    font-size: 18px;
    font-family: var(--ff-ns);
    font-weight: var(--fw-sb);
    line-height: 24px;
    margin-bottom: 16px;
}

.join-as-brand_info__d_g7y p {
    color: var(--gray-one);
    font-size: 14px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-r);
    line-height:  20px;
}

/* section-form */

.join-as-brand_sectionForm__1pwFv {
    padding: 14px 32px 88px;
}

.join-as-brand_sectionForm__1pwFv > h1 {
    color: var(--blue);
    text-align: center;
    font-size: 28px;
    font-family: var(--ff-ns);
    font-weight: var(--fw-b);
    line-height: 36px;
    margin-bottom: 8px;
}

.join-as-brand_sectionForm__1pwFv > p {
    color: var(--blue);
    text-align: center;
    font-size: 12px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-r);
    line-height: 16px;
}

.join-as-brand_sectionForm__1pwFv .join-as-brand_accent__2S29S {
    font-weight: var(--fw-sb);
}

.join-as-brand_signedUp__iXSss {
    color: var(--blue-impt);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 76px;
    margin-bottom: 90px;
}

.join-as-brand_signedUp__iXSss > img {
    width: 100px;
}

.join-as-brand_signedUp__iXSss > h3 {
    font-size: 28px;
    font-family: var(--ff-ns);
    font-weight: var(--fw-sb);
    line-height: 36px;
    margin-top: 26px;
    margin-bottom: 12px;
}
.join-as-brand_signedUp__iXSss > p {
    font-size: 16px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-r);
    line-height: 24px;
}

.join-as-brand_brandForm__3csRt {
    width: 100%;
    margin-top: 40px;
}

.join-as-brand_brandForm__3csRt label {
    display: none;
}

.join-as-brand_brandForm__3csRt ::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--gray-two);
  opacity: 1; /* Firefox */
}

.join-as-brand_brandForm__3csRt ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--gray-two);
  opacity: 1; /* Firefox */
}

.join-as-brand_brandForm__3csRt :-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: var(--gray-two);
}

.join-as-brand_brandForm__3csRt ::-ms-input-placeholder { /* Microsoft Edge */
  color: var(--gray-two);
}

.join-as-brand_brandForm__3csRt > div {
    margin-bottom: 16px;
}

.join-as-brand_brandForm__3csRt input {
    width: 100%;
    color: var(--blue);
    font-size: 16px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-r);
    line-height: 24px;
    border-radius: 5px;
    border: 1px solid var(--blue-two);
    padding: 12px 5.91px 9px;
}

.join-as-brand_brandForm__3csRt > div:last-child {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    margin-bottom: 0px;
}

.join-as-brand_brandForm__3csRt > div:last-child p {
    width: 100%;
    color: var(--red);
    font-size: 14px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-r);
    margin-bottom: 32px;
}

.join-as-brand_brandForm__3csRt button {
    width: 100%;
    background-color: var(--gray-one);
    color: var(--white);
    font-size: 16px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-sb);
    line-height: 24px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 0px 15px rgba(126, 126, 126, 0.3);
    border-radius: 12px;
    border: 0px;
    padding: 9px 0px 12px;
    margin-top: 16px;
}

/******************** 
tablet view styles
*********************/

@media screen and (min-width: 834px) {

    .join-as-brand_border__OxEsF {
        padding: 0px 80px;
    }

    /* header */

    .join-as-brand_header__yHQgE {
        height: 112px;
        padding: 0px 80px;
        -webkit-filter: none;
                filter: none;
    }

    .join-as-brand_websiteLogo__2OaRh {
        width: 44px;
    }

    .join-as-brand_header__yHQgE > p {
        font-size: 18px;
        line-height: 28px;
    }

    .join-as-brand_burgerMenu__1pkYk {
        display: none;
    }

    .join-as-brand_headerBtn__1XxFY {
        width: 198px;
        background-color: var(--blue);
        color: var(--white);
        font-size: 16px;
        line-height: 24px;
        border-radius: 46px;
        padding: 16px 0px 20px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 0px 15px rgba(126, 126, 126, 0.3);

        position: initial;
    }

    .join-as-brand_headerBtn__1XxFY:hover {
        background-color: var(--white);
        color: var(--blue);
    }

    /* section-head */

    .join-as-brand_sectionHead__wusQC {
        background-position: right 51px top;
        padding: 48px 80px 72px;
    }

    .join-as-brand_pageHeadings__3PujB {
        width: 674px;
        text-align: initial;
        margin-bottom: 56px;
    }

    .join-as-brand_pageHeading__27MeN {
        font-size: 48px;
        text-align: left;
        line-height: 64px;
    }

    .join-as-brand_pageHeadings__3PujB > .join-as-brand_accent__2S29S {
        line-height: 70px;
    }

    .join-as-brand_emailInput__AG-4I {
        width: 411px;
    }

    .join-as-brand_emailInput__AG-4I input {
        font-size: 18px;
        line-height: 28px;
        padding: 18px 14px;
        margin-bottom: 24px;
    }

    .join-as-brand_emailInput__AG-4I a {
        font-size: 18px;
        line-height: 28px;
        padding: 18px;
    }

    /* section-information */

    .join-as-brand_sectionInformation__2eI5C {
        padding: 64px 80px 96px;
    }

    .join-as-brand_info__d_g7y {
        grid-gap: 22px;
        gap: 22px;
    }

    .join-as-brand_info__d_g7y:nth-child(odd) {
        flex-direction: row-reverse;
    }

    .join-as-brand_info__d_g7y:nth-child(even) {
        flex-direction: row;
    }

    .join-as-brand_info__d_g7y img {
        width: 328px;
        margin-bottom: 0px;
    }

    .join-as-brand_info__d_g7y h3 {
        text-align: left;
        font-size: 28px;
        line-height: 36px;
        margin-bottom: 24px;
    }

    .join-as-brand_info__d_g7y p {
        text-align: left;
        font-size: 16px;
        line-height: 24px;
    }

    /* section-form */

    .join-as-brand_sectionForm__1pwFv {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 48px 80px 128px;
    }

    .join-as-brand_sectionForm__1pwFv > h1 {
        font-size: 48px;
        line-height: 64px;
        margin-bottom: 16px;
    }

    .join-as-brand_sectionForm__1pwFv > p {
        max-width: 700px;
        font-size: 16px;
        line-height: 24px;
    }

    .join-as-brand_brandForm__3csRt {
        max-width: 740px;
        margin-top: 80px;
    }

    .join-as-brand_brandForm__3csRt > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
    }

    .join-as-brand_brandForm__3csRt label {
        color: var(--blue);
        font-size: 18px;
        font-family: var(--ff-qs);
        font-weight: var(--fw-sb);
        line-height: 28px;
        display: initial;
    }

    .join-as-brand_brandForm__3csRt input {
        max-width: 456px;
        border-width: 3px;
        padding-left: 9.11px;
    }
    
    .join-as-brand_brandForm__3csRt > div:last-child {
        justify-content: flex-end;
        margin-bottom: 0px;
        margin-top: 60px;
    }

    .join-as-brand_brandForm__3csRt > div:last-child p {
        max-width: 456px;
    }

    
    .join-as-brand_brandForm__3csRt button {
        max-width: 456px;
        font-size: 18px;
        line-height: 28px;
        align-self: flex-end;
        padding: 17px 0px 20px;
        margin-top: 0px;
    }

}

/**********
desktop styles
**********/

@media screen and (min-width: 1366px) {

    /* header */

    .join-as-brand_headerBtn__1XxFY {
        font-size: 18px;
        line-height: 28px;
    }

    /* section-head */

    .join-as-brand_sectionHead__wusQC {
        background-position: right 120px top;
        background-size: 500px;
        padding: 64px 80px 72px;
    }

    .join-as-brand_pageHeadings__3PujB {
        width: 845px;
        margin-bottom: 72px;
    }

    /* section -information */

    .join-as-brand_sectionInformation__2eI5C {
        padding: 64px 135px 94px;
    }
    
    .join-as-brand_info__d_g7y {
        grid-gap: 62px;
        gap: 62px;
    }

    .join-as-brand_info__d_g7y img {
        width: 482px;
    }

    .join-as-brand_info__d_g7y h3 {
        font-size: 36px;
        line-height: 48px;
    }

    .join-as-brand_info__d_g7y p {
        font-size: 18px;
        line-height: 28px;
    }

    /* section-form */

    .join-as-brand_brandForm__3csRt {
        margin-top: 84px;
    }

    .join-as-brand_brandForm__3csRt input {
        max-width: 520px;
        padding-left: 10.4px;
    }

    .join-as-brand_brandForm__3csRt > div:last-child p {
        max-width: 520px;
    }
    
    .join-as-brand_brandForm__3csRt button {
        max-width: 520px;
    }

    /*********** 
section-5 
***********/

.join-as-brand_sectionFive__2P-xH {
    width: 100%;
    padding: 88px 80px 40px;
}

.join-as-brand_sectionFive__2P-xH h2 {
    color: var(--blue);
    text-align: center;
    font-size: 36px;
    font-family: var(--ff-ns);
    font-weight: var(--fw-b);
}

.join-as-brand_storeLogos__2pRN4 {
    width: 100%;
    display: flex;
    justify-content: center;
    grid-gap: 15px;
    gap: 15px;
    margin-top: 40px;
    margin-bottom: 88px;
}

.join-as-brand_storeLogos__2pRN4 > img {
    height: 60px;
}

.join-as-brand_sectionFive__2P-xH h3 {
    font-size: 28px;
    font-family: var(--ns);
    font-weight: var(--fw-sb)
}

.join-as-brand_joinOptns__1rIDx {
    display: flex;
    margin-top: 20px;
    margin-bottom: 65px;
}

.join-as-brand_joinOptns__1rIDx > p, .join-as-brand_joinOptns__1rIDx > a {
    font-size: 18px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-r);
    margin-right: 40px;
    text-decoration: underline;
}

.join-as-brand_sectionFive__2P-xH > .join-as-brand_signUpBeta__3ozlp {
    width: -webkit-max-content;
    width: max-content;
    background-color: var(--blue);
    color: var(--white);
    font-size: 18px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-sb);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 0px 15px rgba(126, 126, 126, 0.3);
    margin: 0px;
}

.join-as-brand_App__2hoC- {
    font-family: sans-serif;
    text-align: center;
  }

}
::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--gray-two);
  opacity: 1; /* Firefox */
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--gray-two);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: var(--gray-two);
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: var(--gray-two);
}

.login_body__3eMoM {
    height: 100vh;
    width: 100%;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 32px;
}

.login_heading__2udDF {
    color: var(--blue);
    text-align: center;
    font-size: 28px;
    font-family: var(--ff-ns);
    font-weight: var(--fw-b);
    line-height: 36px;
    margin-bottom: 8px;
}

.login_loginForm__37Cm6 {
    width: 100%;
    min-width: 0px;
    max-width: 400px;
    margin-top: 24px;
}

.login_loginForm__37Cm6 > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}

.login_loginForm__37Cm6 label {
    color: var(--blue);
    display: none;
    font-size: 18px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-sb);
}

.login_loginForm__37Cm6 input {
    width: 100%;
    font-size: 14px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-r);
    line-height: 20px;
    border: 1px solid var(--blue-two);
    border-radius: 5px;
    padding: 12px 6px;
}

.login_loginForm__37Cm6 input:focus {
    outline: 0;
}

.login_loginForm__37Cm6 button {
    width: 100%;
    background-color: var(--gray-one);
    color: var(--white);
    font-size: 16px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-sb);
    line-height: 24px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 0px 15px rgba(126, 126, 126, 0.3);
    border-radius: 12px;
    border: 0px;
    padding: 9px 0px 12px;
    margin-top: 16px;
}

/**********
tablet styles
**********/

@media screen and (min-width: 834px) {

    .login_body__3eMoM {
        padding: 0px 80px;
    }

    .login_heading__2udDF {
        font-size: 48px;
        line-height: 64px;
        margin-bottom: 16px;
    }

    .login_loginForm__37Cm6 {
        max-width: 600px;
        font-size: 16px;
        line-height: 24px;
        margin-top: 80px;
    }

    .login_loginForm__37Cm6 > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
    }

    .login_loginForm__37Cm6 label {
        color: var(--blue);
        font-size: 18px;
        font-family: var(--ff-qs);
        font-weight: var(--fw-sb);
        line-height: 28px;
        display: initial;
    }

    .login_loginForm__37Cm6 input {
        max-width: 456px;
        border-width: 3px;
        padding-left: 9.11px;
    }

    .login_loginForm__37Cm6 > div:last-child {
        justify-content: flex-end;
        margin-bottom: 0px;
        margin-top: 60px;
    }

    .login_loginForm__37Cm6 > div:last-child p {
        max-width: 456px;
    }

    .login_loginForm__37Cm6 button {
        max-width: 456px;
        font-size: 18px;
        line-height: 28px;
        align-self: flex-end;
        padding: 17px 0px 20px;
        margin-top: 0px;
    }
}

/**********
desktop styles
**********/

@media screen and (min-width: 1366px) {
    .login_loginForm__37Cm6 {
        max-width: 700px;
        margin-top: 84px;
    }

    .login_loginForm__37Cm6 input {
        max-width: 520px;
        padding-left: 10.4px;
    }

    .login_loginForm__37Cm6 > div:last-child p {
        max-width: 520px;
    }
    
    .login_loginForm__37Cm6 button {
        max-width: 520px;
    }
}
.add-product_textarea__19MH4 {
    overflow-x: hidden;
    overflow-y: scroll;
    resize: none;
}
.privacy-policy_main__8Cg9R {
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px;
}

.privacy-policy_main__8Cg9R > h1 {
    margin-top: 32px;
    margin-bottom: 64px;
}

.privacy-policy_info__1HwOK {
    max-width: 800px;
}

.privacy-policy_info__1HwOK {
    margin: 12px 0px;
}

.privacy-policy_info__1HwOK h2 {
    margin-top: 32px;
    margin-bottom: 6px;
}

.privacy-policy_main__8Cg9R > p {
    width: 100%;
    max-width: 800px;
    text-align: left;
}
.terms-and-conditions_main__1n_PX {
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px;
}

.terms-and-conditions_main__1n_PX > h1 {
    margin-top: 32px;
    margin-bottom: 64px;
}

.terms-and-conditions_info__KtNNe {
    max-width: 800px;
}

.terms-and-conditions_info__KtNNe {
    margin: 12px 0px;
}

.terms-and-conditions_info__KtNNe h2 {
    margin-top: 32px;
    margin-bottom: 6px;
}

.terms-and-conditions_main__1n_PX > p, h3 {
    width: 100%;
    max-width: 800px;
    text-align: left;
}
:root {
    --blue: #011E46;
    --blue-impt: #015DD3;
    --blue-one: #3C5271;
    --blue-two: #B3CEF2;
    --blue-three: #347DDC;
    --blue-four: #F2F7FD;
    --blue-five: #001735;

    --white: #fff;
    --white-two: #F5F5F5;

    --black: #000;

    --red: #CE0F0F;

    --gray-one: #677890;
    --gray-two: #99A5B5;
    --gray-three: #B3BBC7;
    --gray-four: #E6E8EC;

    --ff-ns: 'Noto Sans';
    --ff-qs: 'Quicksand';

    --fw-r: 400;
    --fw-m: 500;
    --fw-sb: 600;
    --fw-b: 700;
}

* {
    box-sizing: border-box;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

body {
    background-color: #fff;
    background-color: var(--white);
}

p, h1, h2, h3, h4 {
    margin: 0px;
}

p {
    font-family: 'Quicksand';
    font-family: var(--ff-qs);
}

a {
    color: inherit;
    text-decoration: none;
}
::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--gray-two);
  opacity: 1; /* Firefox */
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--gray-two);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: var(--gray-two);
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: var(--gray-two);
}

.manage-seller_body__1zc3I {
    background-color: var(--white);
}

.manage-seller_border__103C4 {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0px 32px;
}

.manage-seller_border__103C4 > div {
    height: 1px;
    width: 100%;
    background-color: var(--gray-four);
}

.manage-seller_btn__1ZVU9 {
    cursor: pointer;
}

/* header */

.manage-seller_header__3qoBX {
    width: 100%;
    background-color: var(--white);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 32px;
    -webkit-filter: drop-shadow(0px 6px 15px rgba(126, 126, 126, 0.25));
            filter: drop-shadow(0px 6px 15px rgba(126, 126, 126, 0.25));
}

.manage-seller_websiteLogo__2KxOQ {
    width: 37px;
}

.manage-seller_header__3qoBX > p {
    color: var(--blue);
    font-size: 16px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-sb);
    line-height: 24px;
}

.manage-seller_burgerMenu__2saXb {
    height: 24px;
    transition: -webkit-transform 200ms ease-out;
    transition: transform 200ms ease-out;
    transition: transform 200ms ease-out, -webkit-transform 200ms ease-out;
}

.manage-seller_headerBtn__3FKPp {
    width: 140px;
    background-color: var(--blue);
    color: var(--white);
    text-align: center;
    font-size: 14px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-sb);
    line-height: 20px;
    border-radius: 8px;
    padding: 6px 0;
    -webkit-transform: scale(1, 0);
            transform: scale(1, 0);
    -webkit-transform-origin: top;
            transform-origin: top;
    transition: -webkit-transform 200ms ease-in;
    transition: transform 200ms ease-in;
    transition: transform 200ms ease-in, -webkit-transform 200ms ease-in;

    position: absolute;
    right: 32px;
    bottom: -35px;
}

.manage-seller_pageHeadings__3e0Gr {
    text-align: center;
    margin: 40px 32px 24px;
}

.manage-seller_pageHeading__38gho {
    color: var(--blue);
    text-align: center;
    font-size: 28px;
    font-family: var(--ff-ns);
    font-weight: var(--fw-b);
    line-height: 36px;
}

.manage-seller_pageHeadings__3e0Gr > .manage-seller_accent__1T_p3 {
    background-color: var(--blue);
    color: var(--blue);
    padding: 0px 5px;
    margin: 1px 0px;
    line-height: 42px;

    position: relative;
    background-image: 
        linear-gradient(var(--blue), var(--blue)),
        linear-gradient(#fff,#fff);
    -webkit-background-clip:
        padding-box,
        text;
    background-clip:
        padding-box,
        text;
    background-size: 0% 100%;
    background-repeat: no-repeat;
    background-position: left;
    -webkit-animation: manage-seller_revealer-text__3S0t8 0.8s 1s forwards;
            animation: manage-seller_revealer-text__3S0t8 0.8s 1s forwards;
    -webkit-animation-delay: 4500ms;
            animation-delay: 4500ms;
}

@-webkit-keyframes manage-seller_revealer-text__3S0t8 {
  0% {
    background-size: 0% 100%;
    color: var(--blue);
  }
  100% {
    background-size: 100% 100%;
    background-position: left;
    color: var(--white);
  }
}

@keyframes manage-seller_revealer-text__3S0t8 {
  0% {
    background-size: 0% 100%;
    color: var(--blue);
  }
  100% {
    background-size: 100% 100%;
    background-position: left;
    color: var(--white);
  }
}

/*********** 
section-info
***********/

.manage-seller_sectionInfo__JM3OT {
    width: 100%;
    background-image: url(/static/media/background-image.0f6094be.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 32px;
}

.manage-seller_infoBox__2AK5_ {
    width: 100%;
    max-width: 350px;
    min-height: 268px;
    aspect-ratio: 1/1;
    background-color: rgba(1, 30, 70, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 17px;
    margin-bottom: 16px;
}

.manage-seller_infoBox__2AK5_:last-child {
    margin-bottom: 0px;
}

.manage-seller_infoBox__2AK5_ > img {
    height: 64px;
    margin-bottom: 24px;
}

.manage-seller_infoBox__2AK5_ > h4 {
    color: var(--gray-two);
    font-size: 16px;
    font-family: var(--ff-ns);
    font-weight: var(--fw-sb);
    line-height: 20px;
    text-align: center;
}

.manage-seller_infoBox__2AK5_ > h4 > span {
    color: var(--white);
}

/*********** 
section-form
***********/

.manage-seller_sectionForm__3aIEb {
    padding: 64px 32px 88px;
}

.manage-seller_sectionForm__3aIEb > h1 {
    color: var(--blue);
    font-size: 28px;
    font-family: var(--ff-ns);
    font-weight: var(--fw-b);
    line-height: 36px;
    text-align: center;
}

.manage-seller_sectionForm__3aIEb > p {
    color: var(--black);
    font-size: 12px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-r);
    line-height: 16px;
    text-align: center;
    margin-top: 8px;
}

.manage-seller_signedUp__317c1 {
    color: var(--blue-impt);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 76px;
    margin-bottom: 90px;
}

.manage-seller_signedUp__317c1 > img {
    width: 100px;
}

.manage-seller_signedUp__317c1 > h3 {
    font-size: 28px;
    font-family: var(--ff-ns);
    font-weight: var(--fw-sb);
    line-height: 36px;
    margin-top: 26px;
    margin-bottom: 12px;
}
.manage-seller_signedUp__317c1 > p {
    font-size: 16px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-r);
    line-height: 24px;
}

.manage-seller_expertForm__2GGaJ {
    margin-top: 40px;
}

.manage-seller_expertForm__2GGaJ > div {
    margin-bottom: 16px;
}

.manage-seller_expertForm__2GGaJ label {
    color: var(--blue);
    font-family: var(--ff-qs);
    font-weight: var(--fw-sb);
    display: none;
}

.manage-seller_expertForm__2GGaJ input, 
.manage-seller_expertForm__2GGaJ select {
    width: 100%;
    background-color: var(--white);
    color: var(--blue);
    font-size: 14px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-r);
    border: 1px solid var(--blue-two);
    border-radius: 5px;
    padding: 12px 6px;
}

.manage-seller_expertForm__2GGaJ input:focus, 
.manage-seller_expertForm__2GGaJ select {
    outline: 0;
}

.manage-seller_addLinks__29uA0 {
    color: var(--gray-one);
    font-size: 16px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-sb);
    line-height: 24px;
    margin: 32px 0px 24px;
}

.manage-seller_addLinks__29uA0 > div:first-child > p {
    margin-bottom: 24px;
}

.manage-seller_addLinks__29uA0 > div:first-child > p > span {
    color: var(--gray-one);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}

.manage-seller_addLinks__29uA0 > div {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.manage-seller_addLinks__29uA0 img {
    height: 32px;
    width: 32px;
    margin-right: 24px;
}

.manage-seller_addLinks__29uA0 > div:first-child {
    justify-content: center;
    margin: 0px;
}

.manage-seller_addLinks__29uA0 > div:first-child > img {
    display: none;
}

.manage-seller_addLink__2rZFp {
    margin-top: 8px;
}

.manage-seller_addLink__2rZFp img {
    height: 20px;
    margin-right: 8px;
}

.manage-seller_addLink__2rZFp p {
    color: var(--blue-three);
    font-size: 14px;
    font-family: var(--ff-ns);
    font-weight: var(--fw-m);
    line-height: 19px;
}

.manage-seller_expertForm__2GGaJ > div:nth-last-child(1) {
    display: initial;
}

.manage-seller_expertForm__2GGaJ > div:nth-last-child(1) p {
    color: var(--red);
    font-size: 14px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-r);
    margin-bottom: 32px;
}

.manage-seller_submitBtn__3NWvm {
    width: 100%;
    background-color: var(--gray-one);
    color: var(--white);
    font-size: 16px;
    font-family: var(--ff-qs);
    font-weight: var(--fw-sb);
    line-height: 24px;
    border: none;
    border-radius: 12px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 0px 15px rgba(126, 126, 126, 0.3);
    padding: 12px 0px;
    margin-top: 32px;
}

/***************
Tablet View styles
***************/

@media screen and (min-width: 834px) {

    /* header */

    .manage-seller_header__3qoBX {
        height: 64px;
        padding: 0px 80px;
    }

    .manage-seller_websiteLogo__2KxOQ {
        width: 44px;
    }

    .manage-seller_header__3qoBX > p {
        font-size: 18px;
        line-height: 28px;
    }

    .manage-seller_burgerMenu__2saXb {
        display: none;
    }

    .manage-seller_headerBtn__3FKPp {
        width: 196px;
        background-color: var(--blue);
        color: var(--white);
        font-size: 16px;
        line-height: 24px;
        border-radius: 46px;
        padding: 8px 0px;

        position: initial;
    }

    /* .headerBtn:hover {
        background-color: var(--white);
        color: var(--blue);
    } */

    .manage-seller_pageHeadings__3e0Gr {
        text-align: center;
        margin: 64px 80px 96px;
    }

    .manage-seller_pageHeading__38gho {
        /* width: min-content; */
        font-size: 36px;
        line-height: 48px;
    }

    /* section-info */

    .manage-seller_sectionInfo__JM3OT {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        grid-gap: 24px;
        gap: 24px;
        padding: 0px 80px;
    }

    .manage-seller_infoBox__2AK5_ {
        height: 293px;
        width: 325px;
        padding: 47px 24px;
        margin: 0px;
    }

    .manage-seller_infoBox__2AK5_ > img {
        height: 76px;
    }

    .manage-seller_infoBox__2AK5_ > h4 {
        font-size: 20px;
        line-height: 28px;
    }

    /* section-form */

    .manage-seller_sectionForm__3aIEb {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 76px 80px 128px;
    }

    .manage-seller_sectionForm__3aIEb > h1 {
        font-size: 48px;
        line-height: 64px;
    }

    .manage-seller_sectionForm__3aIEb > p {
        width: 60%;
        font-size: 16px;
        line-height: 24px;
        margin-top: 14px;
    }

    .manage-seller_expertForm__2GGaJ {
        width: 100%;
        max-width: 750px;
        margin-top: 70px;
    }

    .manage-seller_expertForm__2GGaJ div {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
    }

    .manage-seller_expertForm__2GGaJ > div:nth-last-child(2) {
        flex-direction: column;
    }

    .manage-seller_expertForm__2GGaJ label {
        font-size: 18px;
        line-height: 28px;
        display: initial;
    }

    .manage-seller_expertForm__2GGaJ input, 
    .manage-seller_expertForm__2GGaJ select,
    .manage-seller_addLinks__29uA0 > div:first-child > p {
        width: 65%;
        max-width: 475px;
        font-size: 16px;
        line-height: 24px;
        border-width: 3px;
        padding: 9px;
    }

    .manage-seller_addLinks__29uA0 {
        margin: 64px 0px;
    }

    .manage-seller_addLinks__29uA0 > div:first-child > p {
        font-size: 24px;
        line-height: 32px;
        padding: 0px;
        margin-bottom: 34px;
    }

    .manage-seller_addLinks__29uA0 > div:first-child > p > span {
        font-size: 14px;
        line-height: 16px;
    }

    .manage-seller_addLinks__29uA0 > div {
        margin-bottom: 26px;
    }

    .manage-seller_addLinks__29uA0 > div:first-child {
        justify-content: space-between;
    }

    .manage-seller_addLinks__29uA0 > div:first-child > img {
        display: initial;
    }

    .manage-seller_addLinks__29uA0 > div:nth-last-child(1) {
        justify-content: flex-start;
        margin-bottom: 0px;
    }

    .manage-seller_addLinks__29uA0 img {
        height: 47px;
        width: 47px;
    }

    .manage-seller_addLinks__29uA0 > div:first-child {
        margin: 0px;
    }

    .manage-seller_addLink__2rZFp img {
        height: 24px;
    }

    .manage-seller_addLink__2rZFp p {
        font-size: 16px;
        line-height: 22px;
    }

    .manage-seller_submitBtn__3NWvm {
        padding: 17px 0px 20px;
        margin-top: 21px;
    }

    .manage-seller_border__103C4 > div {
        width: 85%;
    }

}


/***************
Desktop View styles
***************/

@media screen and (min-width: 1366px) {

    /* header */

    .manage-seller_pageHeadings__3e0Gr {
        margin-right: 200px;
        margin-left: 200px;
    }

    .manage-seller_pageHeading__38gho {
        /* width: min-content; */
        font-size: 36px;
        line-height: 55px;
    }

    /* section-info */
    
    .manage-seller_sectionInfo__JM3OT {
        height: 372px;
        display: flex;
        justify-content: space-around;
    }

    .manage-seller_infoBox__2AK5_ {
        height: 100%;
        width: 30%;
        max-width: 500px;
        justify-content: center;
        padding: 0px 29px;
    }

    .manage-seller_infoBox__2AK5_ > img {
        height: 96px;
        margin-bottom: 32px;
    }

    /* section-form */

    .manage-seller_sectionForm__3aIEb {
        padding-right: 0px;
        padding-left: 0px;
    }

    .manage-seller_expertForm__2GGaJ {
        width: 60%;
        margin-top: 74px;
    }

    .manage-seller_expertForm__2GGaJ input, 
    .manage-seller_expertForm__2GGaJ select,
    .manage-seller_addLinks__29uA0 > div:first-child > p {
        width: 100%;
        max-width: 518px;
        padding: 9px 10px;
    }

    .manage-seller_addLinks__29uA0 {
        margin: 65px 0px;
    }

    .manage-seller_addLinks__29uA0 > div:first-child > p {
        padding: 0px 0px;
        margin-bottom: 32px;
    }

    .manage-seller_addLinks__29uA0 > div:nth-last-child(2) {
        margin-bottom: 33px;
    }

    .manage-seller_addLinks__29uA0 > div:nth-last-child(1) {
        margin-bottom: 20px;
    }

    .manage-seller_expertForm__2GGaJ > div:nth-last-child(1) p {
        margin-bottom: 32px;
    }

    .manage-seller_border__103C4 > div {
        width: 95%;
    }
    .manage-seller_GeeksForGeeks__3hi4J {
        text-align: left;
        width: auto;
        
        top:0;
    bottom:0;
    word-wrap: break-word;
    }
}
