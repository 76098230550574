p,
h4,
button {
    padding: 0px;
    margin: 0px;
}

.container {
    width: 320px;
    height: 60px;
    background-color: var(--white);
    font-family: var(--ff-qs);
    display: flex;
    align-items: center;
    gap: 12px;
    border-radius: 4px;
    border-left: 8px solid #5cf07b;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 12px;

    transition: right 300ms ease-out;

    position: fixed;
    top: 10px;
    right: -350px;
    z-index: 2;
}

.container img {
    height: 35px;
}

.container > div {
    margin-right: auto;
}

.container h4 {
    color: #626365;
    font-size: 14px;
}

.container > div > p {
    color: #828283;
    font-size: 12px;
}

.container > button {
    background-color: var(--white);
    color: #828283;
    font-size: 10px;
    border: none;
    cursor: pointer;
}

/* @media screen and (max-width: 824px) {
    .container {
        width: 200px;
    }
} */