.main {
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px;
}

.main > h1 {
    margin-top: 32px;
    margin-bottom: 64px;
}

.info {
    max-width: 800px;
}

.info {
    margin: 12px 0px;
}

.info h2 {
    margin-top: 32px;
    margin-bottom: 6px;
}

.main > p {
    width: 100%;
    max-width: 800px;
    text-align: left;
}